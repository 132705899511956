<template>
  <div class="locale-switcher">
    <v-menu offset-y min-width="250px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on">
          <v-img alt="countryFlag" max-width="30px" :src="getImagePath">
          </v-img>
          {{ $i18n.locale }}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="locale in locales"
          :key="locale.code"
          @click="changeLocale(locale)"
        >
          <v-list-item-title>
            <v-img
              id="list-flag"
              max-width="30px"
              :src="getImagePathList(locale.code)"
            >
            </v-img>
            <div id="list-name">{{ locale.name }}</div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import {getSupportedLocales} from "@/util/i18n/supported-locales";

export default {
  data: () => ({ locales: getSupportedLocales() }),
  computed: {
    getImagePath: function() {
      const flagCode = this.$i18n.locale == "en" ? "us" : this.$i18n.locale;
      return "https://cdn.vuetifyjs.com/images/flags/" + flagCode + ".png";
    }
  },
  methods: {
    changeLocale(locale) {
      this.$router.push({
        name: this.$route.path.name,
        params: { locale: `${locale.code}` }
      });
    },
    getImagePathList(code) {
      const flagCode = code == "en" ? "us" : code;
      return "https://cdn.vuetifyjs.com/images/flags/" + flagCode + ".png";
    }
  }
};
</script>
<style>
#list-flag {
  display: inline-block;
  margin-right: 10px;
}

#list-name {
  display: inline-block;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 0 !important;
}
</style>
