<template>
  <v-app>
    <v-card width="1600" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">{{ $t("aOverview.title") }}</h1>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-data-table
            :headers="headers"
            :items="acquisitionInvoices"
            :search="search"
            :sort-by="'timestamp'"
            :sort-desc="[false, true]"
            class="elevation-1"
          >
            <template v-slot:[`item.processed`]="{ item }">
                <v-chip :color="getItemColor(item.processed)">
                    {{ getItemStatusText(item.processed) }}
                </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom><template #activator="{ on, attrs }">
                <v-icon small class="mr-2" @click="showItem(item)" v-bind="attrs" v-on="on">mdi-magnify</v-icon>
              </template><span>{{$t("aOverview.tt_detail")}}</span></v-tooltip>

              <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                <v-icon small @click="onClickDelete(item)" v-bind="attrs" v-on="on">mdi-delete</v-icon>
              </template><span>{{$t("aOverview.tt_delete")}}</span></v-tooltip>

<!--              <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                <v-icon small :disabled="getItemProccessed(item.processed)" @click="onRemindUser(item)" v-bind="attrs" v-on="on">mdi-email</v-icon>
              </template><span>{{$t("aOverview.tt_remind")}}</span></v-tooltip>
-->
            </template>

          </v-data-table>
          <v-dialog v-model="dialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ $t("aOverview.details") }}</span>
                <v-spacer/>
                <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" :color="getStatusColor()" @click ="changeStatus()">
                    {{ getStatus() }}
                  </v-chip>
                </template><span>{{$t("aOverview.tt_status")}}</span></v-tooltip>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-list name="shownItemList">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-account-circle</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.name") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.ticketCreator
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-gmail</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.email") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <a :href="'mailto:' + shownItem.email">{{
                              shownItem.email
                            }}</a>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-lightbulb-on-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.reason") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.purchasePurpose
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.acquisition") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.itemName
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-cash-multiple</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.price") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end"
                            >{{ shownItem.itemPrice }} €</v-list-item-content
                          >
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-thought-bubble-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.notes") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.additionalInformation
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="shownItem.receiptUrl != undefined">
                          <v-list-item-icon>
                            <v-icon>mdi-camera</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.receipt") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="downloadReceipt"
                              >{{ $t("aOverview.showReceipt") }}</v-btn
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-account-circle</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.bankAccountOwner") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.bankAccountName
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-bank</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.iban") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.bankAccountIBAN
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-calendar</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.uploadDate") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.timestamp
                          }}</v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">{{
                  $t("aOverview.close")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
        <v-card>
          <v-card-title
            class="headline"
          >
           {{ $t("aOverview.deleteHeadline") }}
          </v-card-title>
          <v-card-text>
            {{ $t("aOverview.deleteText") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="(deleteDialog = false), deleteItem()"
              v-t="$t('aOverview.delete')"
            ></v-btn>
            <v-btn
              text
              @click="deleteDialog = false"
              v-t="$t('aOverview.cancel')"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="remindDialog" persistent max-width="290">
        <v-card>
          <v-card-title
              class="headline"
          >
            {{ $t("aOverview.remindHeadline") }}
          </v-card-title>
          <v-card-text>
            {{ $t("aOverview.remindText") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                text
                @click="(remindDialog = false), remindUser()"
                v-t="$t('aOverview.remind')"
            ></v-btn>
            <v-btn
                text
                @click="remindDialog = false"
                v-t="$t('aOverview.cancel')"
            ></v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="successfullyRemindDialog" persistent max-width="290">
      <v-card>
        <v-card-title
            class="headline"
        >
          {{ $t("aOverview.successfullyRemindText") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="successfullyRemindDialog = false"
              v-t="$t('aOverview.ok')"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {db, storage} from "../firebaseConfig.js";

export default {
  data: () => ({
    search: "",
    dialog: false,
    deleteDialog: false,
    remindDialog: false,
    receiver: "",
    successfullyRemindDialog: false,
    deleteIndex: 0,
    headers: [],
    shownIndex: -1,
    shownItem: {
      itemName: "",
      itemPrice: 0,
      purchasePurpose: "",
      additionalInformation: "",
      bankAccountName: "",
      bankAccountIBAN: "",
      receiptUrl: "",
      ticketCreator: "",
      timestamp: ""
    },
    acquisitionInvoices: []
  }),
  created() {
    this.headers = [
      { text: this.$t("aOverview.status"), value: "processed" },
      { text: this.$t('aOverview.uploadDate'), value: 'timestamp'},
      { text: this.$t("aOverview.user"), value: "ticketCreator" },
      { text: this.$t("aOverview.acquisition"), value: "itemName" },
      { text: this.$t("aOverview.reason"), value: "purchasePurpose" },
      { text: this.$t("aOverview.price"), value: "itemPrice" },
      { text: this.$t("aOverview.actions"), value: "actions", sortable: false }
    ];
    this.getData();
  },
  methods: {
    async getData() {
      var acquisitionInvoiceArray = [];
      db.collection("AcquisitionInvoice")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            //console.log(doc.id, " => ", doc.data());
            let entry = doc.data();
            entry.id = doc.id;
            //console.log(entry);
            acquisitionInvoiceArray.push(entry);
          });
        });
      this.acquisitionInvoices = acquisitionInvoiceArray;
    },
    showItem(item) {
      this.shownIndex = this.acquisitionInvoices.indexOf(item);
      this.shownItem = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem() {
      const id = this.shownItem.id;
      this.acquisitionInvoices.splice(this.deleteIndex, 1);
      db.collection("AcquisitionInvoice")
        .doc(id)
        .delete()
        .then(function() {
          console.log("Document successfully deleted!");
        })
        .catch(function(error) {
          console.error("Error removing document: ", error);
        });
      // delete receipts
      let storageReference = storage.ref(
        "AcquisitionInvoicePictures/" + this.shownItem.receiptUrl
      );
      storageReference
        .delete()
        .then(function() {
          console.log("Receipt deleted.");
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    remindUser() {
      //send mail to user

      this.successfullyRemindDialog = true;
    },
    downloadReceipt() {
      console.log(this.shownItem.receiptUrl);
      let storageReference = storage.ref(
        "AcquisitionInvoicePictures/" + this.shownItem.receiptUrl
      );
      storageReference
        .getDownloadURL()
        .then(function(url) {
          var xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = function() {
            var blob = xhr.response;
            let suffixName = "";
            switch (blob.type) {
              case "image/jpeg":
                suffixName = ".jpg";
                break;
              case "image/png":
                suffixName = ".png";
                break;
              case "image/bmp":
                suffixName = ".bmp";
                break;
              case "application/pdf":
                suffixName = ".pdf";
                break;
              default:
                break;
            }
            var link = document.createElement("a");
            link.href = xhr.responseURL;
            link.download = "receipt" + suffixName;
            window.open(link);
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch(function() {
          console.log("something went wrong...");
        });
    },
    //returns color of the shownItem
    getStatusColor () {
        let color = 'gray';
        if(this.shownItem.processed === false) {
            color = 'orange';
        } else if (this.shownItem.processed === true) {
            color = 'green';
        }
        return color;
    },
    getItemColor (itemStatus) {
        let color = 'gray';
        if(itemStatus === false) {
            color = 'orange';
        } else if (itemStatus === true) {
            color = 'green';
        }
        return color;
    },
    getItemProccessed (itemStatus) {
      let inactive = true;
      if(itemStatus === false) {
        inactive = false;
      } else if (itemStatus === true) {
        inactive = true;
      }
      return inactive;
    },
    getItemStatusText (itemStatus) {
        if(itemStatus === false) {
            return this.$t('aOverview.pending');
        }else if(itemStatus === true) {
            return this.$t('aOverview.processed');
        }else {
            return this.$t('aOverview.undefined');
        }
    },
    getStatus () {
        if(this.shownItem.processed === false) {
            return this.$t('aOverview.pending');
        }else if(this.shownItem.processed === true) {
            return this.$t('aOverview.processed');
        }else {
            return this.$t('aOverview.undefined');
        }
    },
    changeStatus () {
        if(this.shownItem.processed === true) {
            this.shownItem.processed = false;
        }else {
            this.shownItem.processed = true;
        }
        db.collection("AcquisitionInvoice")
            .doc(this.shownItem.id)
            .update({
                processed: this.shownItem.processed
            })
            .catch(() => {
                console.log('Could not update Status!');
            }).then(this.getData);
    },
    onClickDelete (item) {
      this.deleteDialog = true;
      this.shownItem = item;
      this.deleteIndex = this.acquisitionInvoices.indexOf(item);
    },
    onRemindUser (item) {
      this.remindDialog = true;
      this.shownItem = item;
      this.remindIndex = this.acquisitionInvoices.indexOf(item);
    }
  }
};
</script>