<template>
  <v-app>
    <div class="pa-md-10" xl="8" lg="9" md="12" sm="12" xs="12">
      <v-container>
        <v-row class="text-center">
          <v-col style="border:solid 0px #777" frameborder="0" scrolling="no">

            <h1 class="pa-md-4" v-t="'home.about'"/>

            <div v-t="'home.abouttext'"/>

          </v-col>
        </v-row>
        <v-row>
          <v-col class="home-card-col" frameborder="0" scrolling="no">
            <v-card width="800" class="mx-auto mt-5 pa-md-4">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 pa-md-4" v-t="'nav.tab1'"/>
                  <v-list-item-subtitle class="pa-md-4" v-t="'home.purchaseRefund'"/>
                </v-list-item-content>
                <v-list-item-avatar tile size="80">
                  <v-icon size="80">
                    mdi-cart-variant
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn text color="success" @click="goToPurchaseRefund()">
                  <v-icon right>
                    mdi-chevron-double-right
                  </v-icon>
                  {{ $t("home.btnPurchaseRefund") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>

          <v-col class="home-card-col" frameborder="0" scrolling="no">
            <v-card width="800" class="mx-auto mt-5 pa-md-4">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 pa-md-4" v-t="'nav.tab2'"/>
                  <v-list-item-subtitle class="pa-md-4" v-t="'home.travelExpenses'"/>
                </v-list-item-content>
                <v-list-item-avatar tile size="80">
                  <v-icon size="80">
                    mdi-train-car
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn text color="success" @click="goToTravelExpenses()">
                  <v-icon right>
                    mdi-chevron-double-right
                  </v-icon>
                  {{ $t("home.btnTravelExpense") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="home-card-col" frameborder="0" scrolling="no">
            <v-card width="800" class="mx-auto mt-5 pa-md-4">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 pa-md-4" v-t="'nav.tab3'"/>
                  <v-list-item-subtitle class="pa-md-4" v-t="'home.tripInvoiceOverview'"/>
                </v-list-item-content>
                <v-list-item-avatar tile size="80">
                  <v-icon size="80">
                    mdi-text-box-multiple
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn text color="success" @click="goToTripInvoiceOverview()">
                  <v-icon right>
                    mdi-chevron-double-right
                  </v-icon>
                  {{ $t("home.btnTripInvoiceOverview") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col class="home-card-col" frameborder="0" scrolling="no">
            <v-card width="800" class="mx-auto mt-5 pa-md-4">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 pa-md-4" v-t="'nav.tab4'"/>
                  <v-list-item-subtitle class="pa-md-4" v-t="'home.purchaseRefundOverview'"/>
                </v-list-item-content>
                <v-list-item-avatar tile size="80">
                  <v-icon size="80">
                    mdi-format-list-checkbox
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn text color="success" @click="goToPurchaseRefundOverview()">
                  <v-icon right>
                    mdi-chevron-double-right
                  </v-icon>
                  {{ $t("home.btnPurchaseRefundOverview") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="home-card-col" frameborder="0" scrolling="no">
            <v-card width="800" class="mx-auto mt-5 pa-md-4">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 pa-md-4" v-t="'nav.tab5'"/>
                  <v-list-item-subtitle class="pa-md-4" v-t="'home.userOverview'"/>
                </v-list-item-content>
                <v-list-item-avatar tile size="80">
                  <v-icon size="80">
                    mdi-account-group
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn text color="success" @click="goToUserOverview()">
                  <v-icon right>
                    mdi-chevron-double-right
                  </v-icon>
                  {{ $t("home.btnUserOverview") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col class="home-card-col" frameborder="0" scrolling="no">
            <v-card width="800" class="mx-auto mt-5 pa-md-4">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 pa-md-4" v-t="'Legal'"/>
                  <v-list-item-subtitle class="pa-md-4" v-t="'Legal background and data policy......'"/>
                </v-list-item-content>
                <v-list-item-avatar tile size="80">
                  <v-icon size="80">
                    mdi-format-section
                  </v-icon>
                </v-list-item-avatar>
              </v-list-item>


            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-app>
</template>

<script>


export default {
  name: "App",
  components: {},
  data: () => ({
    calenderWidth: 600,
    calenderHight: 300
  }),
  created() {
    console.log(this.$store.state.isAdmin + " created");
  },
  mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    console.log(this.$store.state.isAdmin + " mounted");
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      if (window.innerWidth >= 700) {
        this.calenderWidth = 600;
        this.calenderHight = 300;
      } else if (window.innerWidth >= 600) {
        this.calenderWidth = 500;
        this.calenderHight = 300;
      } else if (window.innerWidth >= 500) {
        this.calenderWidth = 400;
        this.calenderHight = 300;
      } else if (window.innerWidth >= 420) {
        this.calenderWidth = 350;
        this.calenderHight = 300;
      } else {
        this.calenderWidth = 300;
        this.calenderHight = 300;
      }
    },
    goToPurchaseRefund() {
      this.$router.push('acquisition_invoice');
    },
    goToTravelExpenses() {
      this.$router.push('trip_invoice');
    },
    goToTripInvoiceOverview() {
      if (this.$store.state.isAdmin) {
        this.$router.push('trip_invoice_overview');
      } else if (!this.$store.state.isAdmin) {
        this.$store.dispatch('setSnackbar', {
          showing: true,
          color: 'red',
          text: this.$t("home.adminSectionSnackbar")
        })
      } else {
        this.$store.dispatch('setSnackbar', {
          showing: true,
          color: 'red',
          text: this.$t("home.adminSectionFailSnackbar")
        })
      }
    },
    goToPurchaseRefundOverview() {
      if (this.$store.state.isAdmin) {
        this.$router.push('acquisition_invoice_overview');
      } else if (!this.$store.state.isAdmin) {
        this.$store.dispatch('setSnackbar', {
          showing: true,
          color: 'red',
          text: this.$t("home.adminSectionSnackbar")
        })
      } else {
        this.$store.dispatch('setSnackbar', {
          showing: true,
          color: 'red',
          text: this.$t("home.adminSectionFailSnackbar")
        })
      }
    },
    goToUserOverview() {
      if (this.$store.state.isAdmin) {
        this.$router.push('user_overview');
      } else if (!this.$store.state.isAdmin) {
        this.$store.dispatch('setSnackbar', {
          showing: true,
          color: 'red',
          text: this.$t("home.adminSectionSnackbar")
        })
      } else {
        this.$store.dispatch('setSnackbar', {
          showing: true,
          color: 'red',
          text: this.$t("home.adminSectionFailSnackbar")
        })
      }
    },
    goToLegal() {

    }
  }

};
</script>

<style scoped>

.home-card-col {
  border: solid 0 #777;
}

.home-card-col .v-card {
  /* This prevents large buttons from spilling out of the cards on small screens. */
  overflow: hidden;
}

</style>
