<template>
  <v-app>
    <v-card width="800" class="mx-auto mt-5" v-on:keyup.enter="signUp">
      <v-dialog v-model="infoDialog" max-width="600px">
              <v-card>
                  <v-card-title>
                      <h3 class="display-1" v-t="'signup.infoPopup'" />

                      
                  </v-card-title>

                  <v-card-text>
                      <div v-t="'signup.infotext'" />
                  </v-card-text>

                  <v-card-actions>
                      <v-spacer/>
                      <v-btn color="blue darken-1" text @click="infoDialog = false" >{{ $t('tOverview.close') }}</v-btn>
                  </v-card-actions>
              </v-card>
          </v-dialog>  
      <v-card-title>
        <h1 class="display-1" v-t="'signup.register'" />
        <v-row>
              <v-col>
                
                <span
                  class="float-right"
                  style="cursor:pointer"
                  @click="infoDialog = true"
                >
                  <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                    <v-icon small @click="onClickDelete(item)" v-bind="attrs" v-on="on">mdi-lightbulb-on-outline</v-icon>
                  </template><span>{{$t("signup.tt_information")}}</span></v-tooltip>
                </span>
              </v-col>
            </v-row>
      </v-card-title>
      <v-card-text>
        <v-form ref="signupForm" v-model="valid" lazy-validation>
          <v-text-field
            v-model="email"
            v-bind:label="$t('signup.email')"
            prepend-icon="mdi-gmail"
            :rules="emailRules"
          />
          <v-text-field
            v-model="password"
            v-bind:label="$t('signup.password')"
            :type="showPassword1 ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPassword1 ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword1 = !showPassword1"
            :rules="passwordRules"
          />
          <v-text-field
            v-model="passwordCheck"
            v-bind:label="$t('signup.passwordRepeat')"
            :type="showPassword2 ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :rules="passwordCheckRules"
          />
          <v-text-field
            v-model="firstName"
            v-bind:label="$t('signup.firstName')"
            prepend-icon="mdi-account-circle"
            :rules="requiredRules"
          />
          <v-text-field
            v-model="lastName"
            v-bind:label="$t('signup.lastName')"
            prepend-icon="mdi-account-circle"
            :rules="requiredRules"
          />
          <v-text-field
            v-model="street"
            v-bind:label="$t('signup.street')"
            prepend-icon="mdi-home-account"
            :rules="requiredRules"
          />
          <v-text-field
            v-model="zipCode"
            v-bind:label="$t('signup.zipCode')"
            prepend-icon="mdi-numeric"
            :rules="requiredRules"
          />
          <v-text-field
            v-model="city"
            v-bind:label="$t('signup.city')"
            prepend-icon="mdi-city"
            :rules="requiredRules"
          />

          <v-dialog
            ref="birthdateDialog"
            v-model="birthdateDialogModel"
            :return-value.sync="birthDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="birthDate"
                v-bind:label="$t('signup.birthDate')"
                prepend-icon="mdi-calendar"
                :close-on-content-click="false"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="requiredRules"
                
              ></v-text-field>
            </template>
            <v-date-picker v-model="birthDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="birthdateDialogModel = false"
                
                
                v-t="'signup.dialogCancelButton'"
              />
              <v-btn
                text
                color="primary"
                @click="$refs.birthdateDialog.save(birthDate); validateAge(birthDate)"
                
                v-t="'signup.dialogOkButton'"
              />
            </v-date-picker>
          </v-dialog>
          <v-text-field
            v-model="birthPlace"
            v-bind:label="$t('signup.birthPlace')"
            prepend-icon="mdi-hospital-building"
            :rules="requiredRules"
          />
          <div class="pt-3">
            <v-row align="center" justify="center" v-bind:style="{ height: deviceHeight * 0.6 + 'px',}">
              <v-col class="fill-height" height="500">
                <v-row no-gutters align="center" justify="center">
                  <v-text-field
                    v-model="reichsregisterNumber"
                    v-bind:label="$t('signup.reichsregisterNummer')"
                    prepend-icon="mdi-numeric"
                    hint="Optional">
                  </v-text-field>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon right="true" color="primary" dark v-on="on"
                        >mdi-information-outline</v-icon
                      >
                    </template>
                    <span v-t="'signup.reichsregNrInformation'" />
                  </v-tooltip>
                </v-row>
              </v-col>
            </v-row>
          </div>
          <v-text-field
            v-model="identificationNumber"
            v-bind:label="$t('signup.identifikationsNummer')"
            prepend-icon="mdi-card-account-details"
            hint="Optional"
          />
          <v-checkbox
            align="center"
            v-model="otherBankAccountName"
            v-bind:label="$t('signup.otherBankAccountName')"
          ></v-checkbox>
          <v-text-field
            v-model="bankAccountName"
            v-bind:label="$t('signup.bankAccountName')"
            v-show="otherBankAccountName"
            prepend-icon="mdi-account-plus"
          ></v-text-field>
          <v-text-field
            v-mask="'AA## NNNN NNNN NNNN NNNN NNNN NNN?N NNN?N'"
            v-model="iban"
            v-bind:label="$t('signup.IBAN')"
            hint="Optional"
            :rules="ibanRules"
            prepend-icon="mdi-bank"
          />
          <v-dialog
            ref="entryDialog"
            v-model="entryDialogModel"
            :return-value.sync="entryDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="entryDate"
                v-bind:label="$t('signup.entryDate')"
                prepend-icon="mdi-calendar-alert"
                :close-on-content-click="false"
                readonly
                v-bind="attrs"
                v-on="on"
                :rules="requiredRules"
              ></v-text-field>
            </template>
            <v-date-picker v-model="entryDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="primary"
                @click="entryDialogModel = false"
                v-t="'signup.dialogCancelButton'"
              />
              <v-btn
                text
                color="primary"
                @click="$refs.entryDialog.save(entryDate)"
                v-t="'signup.dialogOkButton'"
              />
            </v-date-picker>
          </v-dialog>
          <v-radio-group
            v-model="gender"
            v-bind:label="$t('signup.gender')"
            :row="true"
            prepend-icon="mdi-gender-transgender"
          >
            <v-radio v-bind:label="$t('signup.genderMale')" value="male" />
            <v-radio v-bind:label="$t('signup.genderFemale')" value="female" />
            <v-radio v-bind:label="$t('signup.genderOther')" value="other" />
          </v-radio-group>
          <v-radio-group
            v-model="employment"
            v-bind:label="$t('signup.employment')"
            :row="true"
            prepend-icon="mdi-office-building"
          >
            <v-radio
              v-bind:label="$t('signup.employmentEmployed')"
              value="employed"
            />
            <v-radio
              v-bind:label="$t('signup.employmentUnemployed')"
              value="unemployed"
            />
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="primary" dark v-on="on"
                  >mdi-information-outline</v-icon
                >
              </template>
              <span v-t="'signup.employmentInformation'" />
            </v-tooltip>
          </v-radio-group>
          <v-checkbox
            v-model="serviceAgreement"
            :rules="serviceRules"
            value="true"
          >
            <template v-slot:label>
              <div>
                <a v-t="'signup.serviceAgreement'" style="color:grey" />
                <a
                  @click="openNewTab()"
                  v-t="'signup.link'"
                />
              </div>
            </template>
          </v-checkbox>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
                            <v-col>
                            <span v-t='"acInvoice.required"'></span>
                            </v-col>
      <v-card-actions>
        <v-btn to="/log-in" color="info" v-t="'signup.buttonLogin'" min-width="160"/>
        <v-spacer></v-spacer>
        <v-btn
          @click="signUp"
          :disabled="!valid"
          color="success"
          v-t="'signup.buttonRegister'"
          min-width="160"
        />
      </v-card-actions>
    </v-card>
    <v-row>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title
            class="headline"
            v-t="'signup.dialogHeadline'"
          ></v-card-title>
          <v-card-text v-t="'signup.dialogBody'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="(dialog = false), confirmAgeDialog(true)"
              v-t="$t('tInvoice.yes')"
            ></v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="(dialog = false), confirmAgeDialog(false)"
              v-t="$t('tInvoice.no')"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-app>
</template>

<script>
import firebase from "firebase";
import {db} from "../firebaseConfig.js";
import {validateIban} from "../util/validation/iban-validation";

export default {
  name: "Signup",
  data() {
    return {
      valid: false,
      infoDialog: false,
      email: "",
      password: "",
      passwordCheck: "",
      firstName: "",
      lastName: "",
      street: "",
      zipCode: "",
      city: "",
      birthDate: "",
      birthPlace: "",
      entryDate: new Date().toISOString().substr(0, 10),
      gender: "male",
      employment: "employed",
      reichsregisterNumber: "",
      identificationNumber: "",
      iban: "",
      serviceAgreement: "",
      bankAccountName: "",
      otherBankAccountName: false,
      showPassword1: false,
      showPassword2: false,
      birthdateDialogModel: false,
      entryDialogModel: false,
      pathname: window.location.pathname,
      snackbarText: "",
      dialog: false,

      emailRules: [
        v => !!v || this.$t("signup.emailRulesRequired"),
        v => /.+@.+\..+/.test(v) || this.$t("signup.emailRulesValid")
      ],
      passwordRules: [
        v => !!v || this.$t("signup.passwordRulesRequired"),
        v => ( /\d/.test(v) && /[a-z]/.test(v) && /[A-Z]/.test(v) && /[!@#$%^&*)(+=._-]/.test(v) ) || this.$t("signup.passwordRulesInvalid"),
        v => (v || "").length >= 6 || this.$t("signup.passwordRulesLength")
      ],
      passwordCheckRules: [
        v => !!v || this.$t("signup.passwordCheckRulesRequired"),
        v => v === this.password || this.$t("signup.passwordCheckRulesNoMatch")
      ],
      ibanRules: [
        v => validateIban(v.replace(/\s/g, '')) || this.$t("signup.ibanRulesValid")
      ],
      requiredRules: [v => !!v || this.$t("signup.requiredRules")],
      serviceRules: [v => !!v || this.$t("signup.serviceRules")]
    };
  },
  methods: {
    signUp: function() {
      if (this.$refs.signupForm.validate()) {
        firebase
          .auth()
          .createUserWithEmailAndPassword(this.email, this.password)
          .then(
            () => {
              this.$router.replace("home");

              if (this.otherBankAccountName === false && this.iban !== "") {
                this.bankAccountName = this.firstName + " " + this.lastName;
              } 
              db.collection("User")
                .doc(firebase.auth().currentUser.uid)
                .set({
                  firstName: this.firstName,
                  lastName: this.lastName,
                  email: this.email,
                  street: this.street,
                  zipCode: this.zipCode,
                  city: this.city,
                  birthDate: this.birthDate,
                  birthPlace: this.birthPlace,
                  gender: this.gender,
                  employment: this.employment,
                  reichsregisterNumber: this.reichsregisterNumber,
                  identificationNumber: this.identificationNumber,
                  bankAccountName: this.bankAccountName,
                  IBAN: this.iban,
                  entryDate: this.entryDate,
                  serviceAgreement: this.serviceAgreement
                })
                .catch(function(error) {
                  console.error(
                    "Error while writing into the database: ",
                    error
                  );
                });
            }
          )
          .catch(() => {
            this.$store.dispatch('setSnackbar', {
              showing: true,
              color: 'red',
              text: this.$t("signup.snackbarText")
            })
          })
          ;
      } else {
        this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'red',
           text: this.$t("signup.snackbarTextFillForm")
          })
      }
    },
    validateAge(birthDateInput) {
      console.log("test:" + birthDateInput);
      var birthdate = new Date(birthDateInput)
      console.log("birthdate:" + birthdate);
      var sysdate = new Date();
      console.log(sysdate);
      var age = (sysdate.getTime() - birthdate.getTime());
      console.log(age);
      console.log(409968000000  - age);
      if(age < 409968000000) {
        this.dialog = true;
      }
      else {
        return;
      }
    },
    confirmAgeDialog (confirmAge) {
      if(confirmAge) {
        this.dialog = false;
      } else {
        this.snackbar = true;
        this.snackbarText = this.$t("signup.snackbarNoParentalConsent");
        setTimeout(() => this.$router.push('home'), 2500);
      }
    },
    openNewTab() {
      window.open("privacy_agreement");
    }
  }
};
</script>

<style scoped></style>
