<template>
  <v-app>
    <v-row>
      <v-col class="text-center" xl="8" lg="9" md="12" sm="12" xs="12">
        <v-card width="800" class="mx-auto mt-5">
          <v-card-title>
            <h1 class="display-1" v-t="'userprofile.title'" />
          </v-card-title>
          <v-card-text>
            <v-form ref="userForm" lazy-validation>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.firstName"
                      v-bind:label="$t('userprofile.firstname')"
                      prepend-icon="mdi-account-circle"
                      :disabled="disabled"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.lastName"
                      v-bind:label="$t('userprofile.lastname')"
                      prepend-icon="mdi-account-circle"
                      :disabled="disabled"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.email"
                      v-bind:label="$t('userprofile.email')"
                      prepend-icon="mdi-gmail"
                      :disabled="disabled"
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.street"
                      v-bind:label="$t('userprofile.street')"
                      prepend-icon="mdi-home-map-marker"
                      :disabled="disabled"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.zipCode"
                      v-bind:label="$t('userprofile.zip')"
                      prepend-icon="mdi-numeric"
                      :disabled="disabled"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="user.city"
                      v-bind:label="$t('userprofile.city')"
                      prepend-icon="mdi-city"
                      :disabled="disabled"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-dialog ref="menu"
                      v-model="menuModel" 
                      :return-value.sync="user.birthDate"
                      persistent width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          :close-on-content-click="false"
                          :value="user.birthDate"
                          v-bind:label="$t('userprofile.birthday')"
                          prepend-icon="mdi-calendar"
                          v-on="on"
                          v-bind="attrs"
                          :rules="requiredRules"
                          :disabled="disabled"
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="user.birthDate"
                        scrollable
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1900-01-01"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menuModel = false"
                          >{{ $t("userprofile.DateCancelButton") }}</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menu.save(user.birthDate)"
                          >{{ $t("userprofile.DateSaveButton") }}</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="user.birthPlace"
                      v-bind:label="$t('userprofile.birthPlace')"
                      prepend-icon="mdi-map-marker"
                      :disabled="disabled"
                      :rules="requiredRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.reichsregisterNumber"
                      v-bind:label="$t('userprofile.reichsregisternumber')"
                      prepend-icon="mdi-account-outline"
                      :disabled="disabled"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.identificationNumber"
                      v-bind:label="$t('userprofile.identificationnumber')"
                      prepend-icon="mdi-card-account-details"
                      :disabled="disabled"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-mask="'AA## NNNN NNNN NNNN NNNN NNNN NNN?N NNN?N'"
                      v-model="user.IBAN"
                      v-bind:label="$t('userprofile.iban')"
                      prepend-icon="mdi-bank"
                      :disabled="disabled"
                      :rules="ibanRules"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="user.bankAccountName"
                      v-bind:label="$t('userprofile.bankAccountName')"
                      prepend-icon="mdi-account"
                      :disabled="disabled"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row>
                  <v-col>

                    <v-dialog 
                    ref="menuEntry" 
                    v-model="menuEntryModel" 
                    :return-value.sync="user.entryDate"
                    persistent width="290px">

                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="user.entryDate"
                          :close-on-content-click="false"
                          v-bind:label="$t('userprofile.entryDate')"
                          prepend-icon="mdi-calendar-alert"
                          :disabled="disabled"
                          v-on="on"
                          v-bind="attrs"
                          :rules="requiredRules"
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="user.entryDate"
                        scrollable
                        min="1950-01-01"
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          color="primary"
                          @click="menuEntryModel = false"
                          >{{ $t("userprofile.DateCancelButton") }}</v-btn
                        >
                        <v-btn
                          text
                          color="primary"
                          @click="$refs.menuEntry.save(user.entryDate)"
                          >{{ $t("userprofile.DateSaveButton") }}</v-btn
                        >
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row>
                  <v-container fluid>
                    <v-radio-group
                      v-model="user.gender"
                      :disabled="disabled"
                      row
                      prepend-icon="mdi-gender-transgender"
                    >
                      <v-radio
                        v-bind:label="$t('userprofile.female')"
                        value="female"
                      ></v-radio>
                      <v-radio
                        v-bind:label="$t('userprofile.male')"
                        value="male"
                      ></v-radio>
                      <v-radio
                        v-bind:label="$t('userprofile.other')"
                        value="other"
                      ></v-radio>
                    </v-radio-group>
                  </v-container>
                </v-row>

                <v-row>
                  <v-col>
                    <v-container fluid>
                      <v-radio-group
                        v-model="user.employment"
                        row
                        :disabled="disabled"
                        prepend-icon="mdi-office-building"
                      >
                        <v-radio
                          v-bind:label="$t('userprofile.unemployed')"
                          value="unemployed"
                        ></v-radio>
                        <v-radio
                          v-bind:label="$t('userprofile.employed')"
                          value="employed"
                        ></v-radio>
                      </v-radio-group>
                    </v-container>
                  </v-col>
                </v-row>

                <v-col align="left">
                    <span v-t='"acInvoice.required"'></span>
                </v-col>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col class="text-center">
        <v-col>
          <img v-bind:src = "link_profilePicture" style= "width: 100%"/> 

          <v-row>
            <!-- <v-col> -->
              <v-tooltip bottom ><template #activator="{ on, attrs }">
                <v-icon @click="deleteProfilePicture()" class="ms-5 mt-3 align-center" v-bind="attrs" v-on="on">mdi-delete</v-icon>
              </template><span>{{$t("userprofile.tt_delete")}}</span></v-tooltip>     
            <!-- </v-col> -->
            <v-tooltip bottom><template #activator="{ on }">
                <v-file-input 
                  hide-input
                  class="ms-5 align-center"
                  accept=".png, .jpg, .jpeg"
                  @change="changeProfilePicture($event)"
                  @mouseenter.native='on.mouseenter'
                  @mouseleave.native='on.mouseleave'
                  prepend-icon="mdi-paperclip" 
                />
              </template><span>{{$t("userprofile.tt_upload")}}</span></v-tooltip>
          </v-row>

          <div class="my-5 mt-10" @click="saveUser()">
            <v-btn v-if="disabled" large style="width: 200px">
              {{ $t("userprofile.button1") }}
            </v-btn>
            <v-btn v-else large style="width: 200px">
              {{ $t("userprofile.button2") }}
            </v-btn>
          </div>

           <div class="my-5" @click="cancelEditing()">
            <v-btn v-if="!disabled" large style="width: 200px">
              {{ $t("userprofile.buttonCancel") }}
            </v-btn>
          </div>

          <div class="my-5">
            <v-btn :to="'change_password'" large style="width: 200px">{{
              $t("userprofile.button3")
            }}</v-btn>
          </div>      
        </v-col>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import {db, storage} from "../firebaseConfig.js";
import firebase from "./../firebaseConfig";
import {validateIban} from "../util/validation/iban-validation";

export default {
  data() {
    return {
      menuModel: false,
      menuEntryModel: false,
      disabled: true,
      userEmail: firebase.auth().currentUser.email,
      user: {},
      emailRules: [
        v => !!v || this.$t("userprofile.mailRequired"),
        v => /.+@.+\..+/.test(v) || this.$t("userprofile.mailValid")
      ],
     ibanRules: [
        v => validateIban(v.replace(/\s/g, '')) || this.$t("userprofile.ibanValid")
      ],
      requiredRules: [v => !!v || this.$t("userprofile.required")],
      link_profilePicture : "",
      fileName : "",
      img_bool : true,
      default_picture: "default.jpg"
    };
  },

  created() {
    this.getUser();
    this.getProfilePicture();
  },

  methods: {
    getUser() {
      db.collection("User")
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then(doc => {
            this.user = {
            id: doc.id,
            ...doc.data()
            };
        });
    },

    cancelEditing(){
      this.disabled = true;
      console.log("cancel")
      this.snackbar = true;
      this.snackbarText = "Editing canceled without saving";
      this.snackbarColor = "Grey";
      this.getUser()
      
    },

    saveUser() {
      if (this.disabled) {
        //*Bei Klick wird der Text auf Save Data geändert
        this.disabled = false;
      } else {
        if (this.$refs.userForm.validate()) {
          //*Bei Klick wird der Text auf Edit Data geändert

          db.collection("User")
            .doc(this.user.id)
            .update({
              firstName: this.user.firstName,
              lastName: this.user.lastName,
              street: this.user.street,
              zipCode: this.user.zipCode,
              birthDate: this.user.birthDate,
              birthPlace: this.user.birthPlace,
              gender: this.user.gender,
              employment: this.user.employment,
              reichsregisterNumber: this.user.reichsregisterNumber,
              identificationNumber: this.user.identificationNumber,
              IBAN: this.user.IBAN,
              bankAccountName: this.user.bankAccountName,
              entryDate: this.user.entryDate
            })
            .catch(() => {
              this.$store.dispatch('setSnackbar', {
                showing: true,
                color: 'red',
                text: this.$t("userprofile.snackbar1")
              })
            });

          var user = firebase.auth().currentUser;
          user
            .updateEmail(this.user.email)
            .then(() => {
              db.collection("User")
                .doc(this.user.id)
                .update({
                  email: this.user.email
                })
                .catch(() => {
                  this.$store.dispatch('setSnackbar', {
                    showing: true,
                    color: 'red',
                    text: this.$t("userprofile.snackbar1")
                  })
                });
            })
            .catch(error => {
              this.$store.dispatch('setSnackbar', {
                showing: true,
                color: 'red',
                text: error.message
              })
              console.log(error);
            });

          this.disabled = true;
          this.$store.dispatch('setSnackbar', {
            showing: true,
            color: 'green',
            text: this.$t("userprofile.snackbar2")
          })

        } else {
          this.$store.dispatch('setSnackbar', {
            showing: true,
            color: 'red',
            text: this.$t("userprofile.snackbar3")
          })
        }
      }
    },

    getProfilePicture(){

      //set default image
      storage.ref("ProfilePictures")
            .child(this.default_picture)
            .getDownloadURL()
            .then(url => {this.link_profilePicture = url })
            .catch(function(error) {
              console.log(error);
            });
      
      //overwrite by custom picture if available
      storage.ref("ProfilePictures")
            .child(firebase.auth().currentUser.uid)
            .getDownloadURL()
            .then(url => {this.link_profilePicture = url })
            .catch(function(error) {
              console.log(error);
            });
    },

    changeProfilePicture(file){
      if (file!= null){
        var newName = this.user.id;
        const newFile = new File([file], newName, {type: file.type});
        let storageReference = storage.ref("ProfilePictures/" + newName);
        storageReference.put(newFile);

        this.snackbar = true;
        this.snackbarText = this.$t("userprofile.snackbarimagechanged");
        this.snackbarColor = "green";

        

      }
    },

    deleteProfilePicture(){
      storage.ref("ProfilePictures")
             .child(firebase.auth().currentUser.uid)
             .delete()
             .catch(function(error) {
               console.log(error);
             });

      this.snackbar = true;
      this.snackbarText = this.$t("userprofile.snackbarimagedeleted");
      this.snackbarColor = "green";
    }
  }
};
</script>
