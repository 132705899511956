<template>
   <v-app>
    <v-card width="1600" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">{{ $t("aOverview.title") }}</h1>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-data-table
            :headers="headersPR"
            :items="acquisitionInvoices"
            :search="search"
            :sort-by="'timestamp'"
            :sort-desc="[false, true]"
            class="elevation-1"
          >
            <template v-slot:[`item.processed`]="{ item }">
                <v-chip :color="getItemColor(item.processed)">
                    {{ getItemStatusText(item.processed) }}
                </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom><template #activator="{ on, attrs }">
                <v-icon small class="mr-2" @click="showItemAcquisition(item)" v-bind="attrs" v-on="on">mdi-magnify</v-icon>
              </template><span>{{$t("aOverview.tt_detail")}}</span></v-tooltip>
            </template>

          </v-data-table>
          <v-dialog v-model="dialogPR" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ $t("aOverview.details") }}</span>
                <v-spacer/>
                <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                  <v-chip v-bind="attrs" v-on="on" :color="getStatusColor()">
                    {{ getStatus() }}
                  </v-chip>
                </template><span>{{$t("refund.tt_status")}}</span></v-tooltip>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-list name="shownItemList">
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-account-circle</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.name") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.ticketCreator
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-gmail</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.email") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <a :href="'mailto:' + shownItem.email">{{
                              shownItem.email
                            }}</a>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-lightbulb-on-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.reason") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.purchasePurpose
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-pencil</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.acquisition") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.itemName
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-cash-multiple</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.price") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end"
                            >{{ shownItem.itemPrice }} €</v-list-item-content
                          >
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-thought-bubble-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.notes") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.additionalInformation
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="shownItem.receiptUrl != undefined">
                          <v-list-item-icon>
                            <v-icon>mdi-camera</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.receipt") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="downloadReceiptAcquisition"
                              >{{ $t("aOverview.showReceipt") }}</v-btn
                            >
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-account-circle</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.bankAccountOwner") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.bankAccountName
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-bank</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.iban") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.bankAccountIBAN
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-calendar</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("aOverview.uploadDate") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownItem.timestamp
                          }}</v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer/>
                <v-btn color="blue darken-1" text @click="dialogPR = false" >{{ $t('tOverview.close') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card width="1600" class="mx-auto mt-5">
      <v-card-title>
      <h1 class="display-1">{{ $t('tOverview.title') }}</h1>
      <v-spacer/>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        v-bind:label="$t('tOverview.search')"
        single-line
        hide-details
      ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-container>
          
          <v-data-table
              :headers="headersTE"
              :items="tripInvoices"
              :search="search"
              :sort-by="'uploadDate'"
              :sort-desc="[false, true]"
              class="elevation-1"
          >
            <template v-slot:[`item.processed`]="{ item }">
                <v-chip :color="getItemColor(item.processed)">
                    {{ getItemStatusText(item.processed) }}
                </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">

                <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                    <v-icon small class="mr-2" @click="showItemTrip(item)" v-bind="attrs" v-on="on">mdi-magnify</v-icon>
                </template><span>{{$t("tOverview.tt_detail")}}</span></v-tooltip>

            </template>
          </v-data-table>
          <v-dialog v-model="dialogTE" max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ $t("tOverview.details") }}</span>
                        <v-spacer/>
                        <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" :color="getStatusColor()">
                                {{ getStatus() }}
                            </v-chip>
                        </template><span>{{$t("refund.tt_status")}}</span></v-tooltip>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-list name="shownItemList">
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-account-circle</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content >{{ $t('tOverview.name') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.ticketCreator}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-gmail</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content >{{ $t('tOverview.email') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end"><a :href="'mailto:' + shownItem.email">{{shownItem.email}}</a></v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-lightbulb-on-outline</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.reason') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.reason}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item> 
                                            <v-list-item-icon>
                                                <v-icon>mdi-ray-start-arrow</v-icon>
                                            </v-list-item-icon>   
                                            <v-list-item-content>{{ $t('tOverview.start') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.startLocation}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>    
                                            <v-list-item-icon>
                                                <v-icon>mdi-ray-end</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>{{ $t('tOverview.end') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.endLocation}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-thought-bubble-outline</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.notes') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.additionalInfo}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-calendar</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.date') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.tripDate}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-format-list-bulleted-type</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.tripType') + ":" }}</v-list-item-content>
                                            <v-list-item-content v-if="shownItem.oneWay === true" class="align-end">One-way trip</v-list-item-content>
                                            <v-list-item-content v-if="shownItem.oneWay === false" class="align-end">Round trip</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item> 
                                            <v-list-item-icon>
                                                <v-icon>mdi-car</v-icon>
                                            </v-list-item-icon>   
                                            <v-list-item-content>{{ $t('tOverview.vehicle') + ":" }}</v-list-item-content>
                                            <v-list-item-content v-if="shownItem.byCar === true" class="align-end">Car</v-list-item-content>
                                            <v-list-item-content v-if="shownItem.byCar === false" class="align-end">Other</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="shownItem.receiptUrl!= undefined">
                                            <v-list-item-icon>
                                                <v-icon>mdi-camera</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>{{ $t('tOverview.receipt') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">
                                                <v-btn color="blue darken-1" text @click="downloadReceiptTrip" >{{ $t('tOverview.showReceipt') }}</v-btn>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>    
                                            <v-list-item-icon>
                                                <v-icon>mdi-map-marker-distance</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>{{ $t('tOverview.distance') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.distance}} km</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item> 
                                            <v-list-item-icon>
                                                <v-icon>mdi-cash-multiple</v-icon>
                                            </v-list-item-icon>   
                                            <v-list-item-content>{{ $t('tOverview.predictedRefund') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.refundSum}} €</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-account-circle</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.bankAccountOwner') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.bankAccountName}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-bank</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.iban') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.iban}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-calendar</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.uploadDate') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.uploadDate}}</v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn color="blue darken-1" text @click="dialogTE = false" >{{ $t('tOverview.close') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>  
        </v-container>
      </v-card-text>
    </v-card>
  </v-app>
</template>
<script>
import {db, storage} from "../firebaseConfig.js";
import firebase from "./../firebaseConfig";

export default {
  data: () => ({
    search: "",
    headersPR: [],
    headersTE: [],
    dialogPR: false,
    dialogTE: false,
    user: {},
    shownItem: {
      itemName: "",
      itemPrice: 0,
      purchasePurpose: "",
      additionalInformation: "",
      bankAccountName: "",
      bankAccountIBAN: "",
      receiptUrl: "",
      timestamp: ""
    },
    acquisitionInvoices: [],
    tripInvoices: []
  }),
  created() {
    this.headersPR = [
      { text: this.$t("aOverview.status"), value: "processed" },
      { text: this.$t('aOverview.uploadDate'), value: 'timestamp'},
      { text: this.$t("aOverview.acquisition"), value: "itemName" },
      { text: this.$t("aOverview.reason"), value: "purchasePurpose" },
      { text: this.$t("aOverview.price"), value: "itemPrice" },
      { text: this.$t("aOverview.actions"), value: "actions", sortable: false }
    ];
    this.headersTE = [
            { text: this.$t('tOverview.status'), value: 'processed'},
            { text: this.$t('tOverview.uploadDate'), value: 'uploadDate'},
            { text: this.$t('tOverview.reason'), value: 'reason'},
            { text: this.$t('tOverview.start'), value: 'startLocation'},
            { text: this.$t('tOverview.end'), value: 'endLocation'},
            { text: this.$t('tOverview.date'), value: 'tripDate'},
            { text: this.$t('tOverview.actions'), value: 'actions', sortable: false }
        ];
    this.getUser();
  },
  methods: {
    
     async getUser() {
      db.collection("User")
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then(doc => {
          this.user = {
            id: doc.id,
            ...doc.data()
          };
          
          console.log("in getUser():" + this.user.email)
          this.getData();
        });
      return true;
    },
    
    async getData() {
      this.getPurchaseData();
      this.getTravelData();
    },
    async getPurchaseData(){
      var acquisitionInvoiceArray = [];
      db.collection("AcquisitionInvoice").where("email", "==", this.user.email)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            //console.log(doc.id, " => ", doc.data());
            let entry = doc.data();
            entry.id = doc.id;
            //console.log(entry);
            acquisitionInvoiceArray.push(entry);
          });
        });
      this.acquisitionInvoices = acquisitionInvoiceArray;
    },
    async getTravelData(){
      var tripInvoiceArray = [];
      db.collection("TripInvoice").where("email", "==", this.user.email)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let entry = doc.data();
            entry.id = doc.id;
            tripInvoiceArray.push(entry);
          });
        });
      this.tripInvoices = tripInvoiceArray;
    },
    showItemAcquisition(item) {
      this.shownIndex = this.acquisitionInvoices.indexOf(item);
      this.shownItem = Object.assign({}, item);
      this.dialogPR = true;
    },
    showItemTrip (item) {
      this.shownIndex = this.tripInvoices.indexOf(item);
      this.shownItem = Object.assign({}, item);
      this.dialogTE = true;
    },
    downloadReceiptAcquisition() {
      console.log(this.shownItem.receiptUrl);
      let storageReference = storage.ref(
        "AcquisitionInvoicePictures/" + this.shownItem.receiptUrl
      );
      storageReference
        .getDownloadURL()
        .then(function(url) {
          var xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = function() {
            var blob = xhr.response;
            let suffixName = "";
            switch (blob.type) {
              case "image/jpeg":
                suffixName = ".jpg";
                break;
              case "image/png":
                suffixName = ".png";
                break;
              case "image/bmp":
                suffixName = ".bmp";
                break;
              case "application/pdf":
                suffixName = ".pdf";
                break;
              default:
                break;
            }
            var link = document.createElement("a");
            link.href = xhr.responseURL;
            link.download = "receipt" + suffixName;
            window.open(link);
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch(function() {
          console.log("something went wrong...");
        });
    },
    downloadReceiptTrip() {
      let storageReference = storage.ref("TripInvoicePictures/" + this.shownItem.receiptUrl);
      console.log(storageReference);
      storageReference.getDownloadURL().then(function(url) {
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function() {
              var blob = xhr.response;
              let suffixName = '';
              switch(blob.type) {
                  case 'image/jpeg':
                      suffixName = '.jpg';
                      break;
                  case 'image/png':
                      suffixName = '.png';
                      break;
                  case 'image/bmp':
                      suffixName = '.bmp';
                      break;
                  case 'application/pdf':
                      suffixName = '.pdf';
                      break;
                  default:
                      break;
              }
              var link = document.createElement('a');
              link.href = xhr.responseURL;
              link.download = "receipt" + suffixName;
              window.open(link);
          };
          xhr.open("GET", url);
          xhr.send();
      }).catch(function() {
          console.log('something went wrong...');
      });
    },
    //returns color of the shownItem
    getStatusColor () {
        let color = 'gray';
        if(this.shownItem.processed === false) {
            color = 'orange';
        } else if (this.shownItem.processed === true) {
            color = 'green';
        }
        return color;
    },
    getItemColor (itemStatus) {
        let color = 'gray';
        if(itemStatus === false) {
            color = 'orange';
        } else if (itemStatus === true) {
            color = 'green';
        }
        return color;
    },
    getItemStatusText (itemStatus) {
        if(itemStatus === false) {
            return this.$t('aOverview.pending');
        }else if(itemStatus === true) {
            return this.$t('aOverview.processed');
        }else {
            return this.$t('aOverview.undefined');
        }
    },
    getStatus () {
        if(this.shownItem.processed === false) {
            return this.$t('aOverview.pending');
        }else if(this.shownItem.processed === true) {
            return this.$t('aOverview.processed');
        }else {
            return this.$t('aOverview.undefined');
        }
    }
  }
};
</script>