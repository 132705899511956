<template>
  <v-app>
    <v-card width="400" class="mx-auto mt-5" v-on:keyup.enter="login">
      <v-card-title>
        <h1 class="display-1" v-t="'login.login'" />
      </v-card-title>
      <v-card-text>
        <v-form>
          <v-text-field
            v-model="email"
            v-bind:label="$t('login.email')"
            prepend-icon="mdi-account-circle"
          />
          <v-text-field
            v-model="password"
            v-bind:label="$t('login.password')"
            :type="showPassword ? 'text' : 'password'"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" to="/reset_password" v-t="$t('login.ForgotPswd')">
          </v-btn>
      </v-card-actions>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn to="/sign-up" color="info" v-t="'login.buttonRegister'" min-width="120"/>
        <v-spacer></v-spacer>
        <v-btn @click="login" color="success" v-t="'login.buttonLogin'" min-width="120"/>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
import firebase from "firebase";
import i18n from "@/i18n";
import {db} from "../firebaseConfig.js";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: "",
      showPassword: false,
    };
  },

  methods: {
    login: function() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email.trim(), this.password)
        .then(
          () => {
            this.updateMail(); 
            this.$router.replace({
              name: "Home",
              params: { locale: i18n.locale }
            });
          }
        )
        .catch(() => {
          this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'red',
           text: this.$t("login.snackbarText")
          })
        });
    },

    updateMail: function(){
      //update collection email from auth(login) email
      //normally equal, but it needs to be updated after mail recover proccess (compare reset_passwort_from_mail.vue)
      var auth = firebase.auth();

      db.collection("User")
        .doc(auth.currentUser.uid)
        .update({
            email: auth.currentUser.email,
        })
        .catch((error) => {
          console.log(error);
          this.loginSnackbar = true,
          this.snackbarText = this.$t("login.snackbarText2")
        });
    }
  }
};
</script>

<style scoped>
.login {
  margin-top: 40px;
}
input {
  margin: 10px 0;
  width: 20%;
  padding: 15px;
}

p {
  margin-top: 40px;
  font-size: 13px;
}
p a {
  text-decoration: underline;
  cursor: pointer;
}
</style>
