import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Signup from '../views/Signup.vue'
import Login from '../views/Login.vue'
import Reset_password from '../views/Reset_password.vue'
import Userprofile from '../views/Userprofile.vue'
import Trip_invoice from '../views/Trip_invoice.vue'
import Trip_invoice_overview from '../views/Trip_invoice_overview.vue'
import Change_Password from '../views/Change_Password.vue'
import User_Overview from '../views/User_Overview.vue'
import Acquisition_invoice from '../views/Acquisition_invoice.vue'
import Acquisition_invoice_overview from '../views/Acquisition_invoice_overview.vue'
import Refund_overview_user from '../views/Refund_overview_user.vue'
import Privacy_agreement from '../views/Privacy_agreement.vue'
import Reset_password_from_mail from '../views/Reset_password_from_mail.vue'
import Imprint from '../views/Imprint.vue'
import firebase from '../firebaseConfig'
import {db} from "../firebaseConfig.js";
import Root from "./Root"
import i18n, {loadLocaleMessagesAsync} from "@/i18n"
import {setDocumentLang, setDocumentTitle} from "@/util/i18n/document"

Vue.use(VueRouter)

const routes = [{
  path: '/',
  redirect: i18n.locale + '/login'
},
{
  path: '/home',
  redirect: i18n.locale + '/home'
},
{
  path: '/userprofile',
  redirect: i18n.locale + '/userprofile'
},
{
  path: '/sign-up',
  redirect: i18n.locale + '/sign-up'
},
{
  path: '/change_password',
  redirect: i18n.locale + '/change_password'
},
{
   path: '/reset_password_from_mail',
   redirect: i18n.locale + '/reset_password_from_mail'
 },
{
  path: '/trip_invoice',
  redirect: i18n.locale + '/trip_invoice'
},
{
  path: '/acquisition_invoice',
  redirect: i18n.locale + '/acquisition_invoice'
},
{
  path: '/user_overview',
  redirect: i18n.locale + '/user_overview'
},
{
  path: '/privacy_agreement',
  redirect: i18n.locale + '/privacy_agreement'
},
{
  path: '/reset_password',
  redirect: i18n.locale + '/reset_password'
},
{
  path: '/trip_invoice_overview',
  redirect: i18n.locale + '/trip_invoice_overview'
},
{
  path: '/acquisition_invoice_overview',
  redirect: i18n.locale + '/acquisition_invoice_overview'
},
{
  path: '/refund_overview_user',
  redirect: i18n.locale + '/refund_overview_user'
},
{
  path: '/imprint',
  redirect: i18n.locale + '/imprint'
},
{
  path: "/:locale([a-z][a-z])",
  component: Root,
  children: [{
    path: 'login',
    name: 'Login',
    component: Login,
    props: true
  },
  {
    path: 'sign-up',
    name: 'Signup',
    component: Signup
  },
  {
    path: 'imprint',
    name: 'Imprint',
    component: Imprint,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'reset_password',
    name: 'Reset_password',
    component: Reset_password
  },
  {
    path: 'home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'trip_invoice',
    name: 'Trip_invoice',
    component: Trip_invoice,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'trip_invoice_overview',
    name: 'Trip_invoice_overview',
    component: Trip_invoice_overview,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: 'acquisition_invoice',
    name: 'Acquisition_invoice',
    component: Acquisition_invoice,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'acquisition_invoice_overview',
    name: 'Acquisition_invoice_overview',
    component: Acquisition_invoice_overview,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: 'refund_overview_user',
    name: 'Refund_overview_user',
    component: Refund_overview_user,
    meta: {
      requiresAuth: true,
      requiresAdmin: false
    }
  },
  {
    path: 'Change_Password',
    name: 'Change_password',
    component: Change_Password,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'userprofile',
    name: 'Userprofile',
    component: Userprofile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: 'user_overview',
    name: 'User_Overview',
    component: User_Overview,
    meta: {
      requiresAuth: true,
      requiresAdmin: true
    }
  },
  {
    path: 'privacy_agreement',
    name: 'Privacy_agreement',
    component: Privacy_agreement
  },
  {
    path: 'reset_password_from_mail',
    name: 'Reset_password_from_mail',
    component: Reset_password_from_mail
  }
  ]
},
{
  path: '*',
  redirect: i18n.locale + '/login'
}
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

const isAdmin = function () {
  return new Promise((resolve) => {
    if (!firebase.auth().currentUser) {
      resolve(false);
    }
    db.collection("User")
      .doc(firebase.auth().currentUser.uid)
      .get()
      .then(function (doc) {
        resolve(doc.data().admin == true ? true : false);
      });
  })

}

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);
  isAdmin().then((result) => {
    if (to.params.locale === from.params.locale) {
      if (requiresAuth && !currentUser) {
        next({
          name: 'Login',
          params: {
            locale: i18n.locale
          }
        });
      } else if (!requiresAuth && currentUser) {
        next({
          name: 'Home',
          params: {
            locale: i18n.locale
          }
        });
      } else if (requiresAdmin && !result) {
        next({
          name: 'Home',
          params: {
            locale: i18n.locale
          }
        });
      } else {
        next()
      }
      return
    }

    const {
      locale
    } = to.params
    loadLocaleMessagesAsync(locale).then(() => {
      setDocumentLang(locale)
      setDocumentTitle(i18n.t("app.title"))
    })
    if (requiresAuth && !currentUser) {
      next({
        name: 'Login',
        params: {
          locale: i18n.locale
        }
      });
    } else if (!requiresAuth && currentUser) {
      next({
        name: 'Home',
        params: {
          locale: i18n.locale
        }
      });
    } else if (requiresAdmin && !result) {
      next({
        name: 'Home',
        params: {
          locale: i18n.locale
        }
      });
    } else {
      next()
    }
  })
  window.scrollTo(0, 0);
})

export default router
