<template>
  <v-app>
    <link
      href="https://use.fontawesome.com/releases/v5.0.13/css/all.css"
      rel="stylesheet"
    />
    <loading
      class="loader"
      v-if="isLoading"
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :color="color"
      :background-color="bgColor"
    >
    </loading>
    <template v-else>
      <v-app-bar
        app
        color="white"
        light
        shrink-on-scroll
        src="./assets/Muzemix_Banner_LoggedIn.png"
        fade-img-on-scroll
        v-if="
          (!$vuetify.breakpoint.xsOnly && loggedIn && !isAdminBoolean) ||
            ($vuetify.breakpoint.lgAndUp && loggedIn && isAdminBoolean)
        "
      >
        <v-spacer></v-spacer>
        <v-btn>
          <LocaleSwitcher />
        </v-btn>

        <v-btn @click="navToUserprofile" icon color="darkgrey">
          <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">fas fa-user</v-icon>
          </template><span>{{$t("nav.tt_profile")}}</span></v-tooltip>
        </v-btn>

        <v-btn @click="logout" icon color="darkgrey">
          <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">fas fa-sign-out-alt</v-icon>
          </template><span>{{$t("nav.tt_logout")}}</span></v-tooltip>
        </v-btn>

        <template v-slot:extension id="Tabbar">
          <v-tabs grow background-color="#f7f5e7" v-model="activeTab" hide-slider="true">
            <!-- first tab is a "invisible fake tab" which ist selected when the user navigates to the "userprofile"-View -->
            <v-tab class="pa-0" style="min-width:0px" />
            <v-tab
              v-for="tab in visibleTabs"
              @click="changeTab(tab.route)"
              :key="tab.id"
              :to="tab.route"
            >
              {{ $t(tab.name) }}
            </v-tab>
          </v-tabs>
        </template>
      </v-app-bar>

      <v-app-bar
        app
        color="white"
        light
        src="./assets/Muzemix_Banner_LoggedOut.png"
        height="170%"
        v-if="
          !loggedIn ||
            $vuetify.breakpoint.xsOnly ||
            ($vuetify.breakpoint.mdAndDown && isAdminBoolean)
        "
        fade-img-on-scroll
        shrink-on-scroll
      >
        <v-app-bar-nav-icon
          @click.stop="drawer = !drawer"
          v-if="
            ($vuetify.breakpoint.xsOnly && loggedIn) ||
              ($vuetify.breakpoint.mdAndDown && loggedIn && isAdminBoolean)
          "
        >
        </v-app-bar-nav-icon>

        <v-spacer></v-spacer>
        <v-btn>
          <LocaleSwitcher />
        </v-btn>
  
        <v-btn @click="navToUserprofile" icon color="darkgrey" v-if="loggedIn">
          <v-icon>fas fa-user</v-icon>
        </v-btn>
        <v-btn @click="logout" icon color="darkgrey" v-if="loggedIn">
          <v-icon>fas fa-sign-out-alt</v-icon>
        </v-btn>
      </v-app-bar>

      <v-navigation-drawer
        v-if="
          ($vuetify.breakpoint.xsOnly && loggedIn) ||
            ($vuetify.breakpoint.mdAndDown && loggedIn && isAdminBoolean)
        "
        v-model="drawer"
        temporary
        app
      >
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="color_beige--text text--accent-4"
          >
            <v-list-item
              v-for="tab in visibleTabs"
              @click="changeTab(tab.route)"
              :key="tab.id"
              :to="tab.route"
            >
              <v-list-item-title>{{ $t(tab.name) }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>

      <v-content>
        <router-view />
      </v-content>

      <v-snackbar
        v-for="(snackbar, index) in snackbars.filter(s => s.showing)"
        :key="snackbar.text + Math.random()"
        v-model="snackbar.showing"
        :timeout="3000"
        :color="snackbar.color"
        :style="`bottom: ${(index * 70) + 8}px; top: auto;`"
      >
        {{ snackbar.text }}

        <v-btn dark text @click="snackbar.showing = false">
          Close
        </v-btn>
      </v-snackbar>

      <v-footer class="color_beige" v-if="loggedIn" padless>
        <v-card class="flex color_beige" flat tile>
          <v-card-title class="color_beige">
            <div class="footer_content">
              <a class="subheading muzemix_title" href="http://www.muzemix.be/"
                >Muzemix</a
              >
              <v-btn icon >
                <v-icon @click="callImprint()">mdi-comment-alert</v-icon>
              </v-btn>
              <v-btn
                icon
                class="facebook_btn"
                href="https://www.facebook.com/muzemix"
              >
                <v-icon>fab fa-facebook-square</v-icon>
              </v-btn>
              <v-btn
                icon
                class="instagram_btn"
                href="https://www.instagram.com/muzemix"
              >
                <v-icon>fab fa-instagram</v-icon>
              </v-btn>
              <v-btn icon class="mail_btn" href="mailto:info@muzemix.be">
                <v-icon>fas fa-envelope</v-icon>
              </v-btn>
            </div>
          </v-card-title>
        </v-card>
      </v-footer>
    </template>
  </v-app>
</template>

<script>
import firebase from "./firebaseConfig";
import LocaleSwitcher from "@/components/LocaleSwitcher";
import EventBus from "@/EventBus";
import Loading from "vue-loading-overlay";
import {db} from "./firebaseConfig.js";
import {mapState} from 'vuex';

export default {
  name: "App",
  components: { LocaleSwitcher, Loading },
  data: () => ({
    loggedIn: false,
    isLoading: true,
    isAdminBoolean: false,
    color: "#007bff",
    bgColor: "#ffffff",
    activeTab: "",
    normalTabs: [
      { id: "1", name: "nav.home", route: "home" },
      { id: "2", name: "nav.tab1", route: "acquisition_invoice" },
      { id: "3", name: "nav.tab2", route: "trip_invoice" }
    ],
    adminTabs: [
      { id: "4", name: "nav.tab3", route: "trip_invoice_overview" },
      { id: "5", name: "nav.tab4", route: "acquisition_invoice_overview" },
      { id: "6", name: "nav.tab5", route: "user_overview" }
    ],
    notAdminTabs:[
      { id: "4", name: "nav.tab3_2", route: "refund_overview_user" }
    ],
    visibleTabs: [],
    drawer: false,
    group: null
  }),
  created() {
    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        this.loggedIn = true;
        this.refreshTabs();
        this.refreshAdmin();
      } else {
        this.loggedIn = false;
      }
    });
    this.isAdmin().then(response => {
      this.isAdminBoolean = response;
    });
  },
  mounted() {
    EventBus.$on("i18n-load-start", () => (this.isLoading = true));
    EventBus.$on("i18n-load-complete", () => (this.isLoading = false));
    this.activeTab = this.$route;
  },
  computed: {
    ...mapState(['snackbars'])
  },
  methods: {
    logout: function() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
        });
    },
    refreshTabs() {
      this.isAdmin().then(result => {
        if (result) {
          this.visibleTabs = this.normalTabs.concat(this.adminTabs);
        } else {
          this.visibleTabs = this.normalTabs.concat(this.notAdminTabs);
        }
      });
    },
    refreshAdmin() {
      this.isAdmin().then(result => {
        if (result) {
          this.$store.dispatch('setAdmin');
        } else {
          this.$store.dispatch('resetAdmin');
        }
      });
    },
    changeTab(route) {
      this.activeTab = route;
    },
    navToUserprofile(){
      this.$router.push("userprofile");
      this.activeTab = null;
    },
    isAdmin: function() {
      return new Promise(resolve => {
        if (!firebase.auth().currentUser) {
          resolve(false);
        }
        db.collection("User")
          .doc(firebase.auth().currentUser.uid)
          .get()
          .then(function(doc) {
            resolve(doc.data().admin == true ? true : false);
          });
      });
    },
    
    callImprint: function(){
      this.$router.replace("imprint");
    }
  },
  watch: {
    group() {
      this.drawer = false;
    }
  }
};
</script>

<style scoped>
.color_beige {
  background-color: #f7f5e7;
}
.muzemix_title {
  color: inherit;
  font-weight: bold;
  text-decoration: none;
}
.footer_content {
  margin-right: auto;
  margin-left: auto;
}
.loader {
  margin: auto;
}
</style>
