<template>
  <v-app>
      <v-card width="800" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1" v-t="$t('resetPasswordFromMail.title')"></h1>
      </v-card-title>
      <v-card-text>
        <v-form ref="ResetPasswordFromMailForm" v-model="valid">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.syn, rules.min]"
                  :type="show2 ? 'text' : 'password'"
                  name="input-10-2"
                  v-bind:label="$t('resetPasswordFromMail.newPassword')"
                  v-bind:hint="$t('resetPasswordFromMail.newPasswordhint')"
                  class="input-group--focused"
                  id="NewPswd"
                  @click:append="show2 = !show2"
                  required
                  v-model="newPswd"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.passwordMatch]"
                  :type="show3 ? 'text' : 'password'"
                  name="input-10-2"
                  v-bind:label="$t('resetPasswordFromMail.reNewPassword')"
                  v-bind:hint="$t('resetPasswordFromMail.reNewPasswordhint')"
                  class="input-group--focused"
                  id="ReNewPswd"
                  @click:append="show3 = !show3"
                  required
                  v-model="reNewPswd"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <div>
            <v-snackbar
              v-model="snackbar.show"
              id="snackbar"
              :color="snackbar.color"
            >
              {{ snackbar.message }}
              <v-btn @click="snackbar.show = false" id="snackbarBtn" text v-t="$t('resetPasswordFromMail.close')">
              </v-btn>
            </v-snackbar>
          </div>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer />
        <v-btn v-on:click="ChangePassword()" :disabled="!valid" color="success" v-t="$t('resetPasswordFromMail.submit')">
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>


<script>
import firebase from "../firebaseConfig.js";

export default {
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      valid: false,
      newPswd: "",
      reNewPswd: "",
      password: "Password",
      mode: "",
      actionCode: "",
      continueUrl: "",
      lang: "",
      rules: {
        required: value => !!value || this.$t('resetPasswordFromMail.pswdRequired'),
        syn: v => ( /\d/.test(v) && /[a-z]/.test(v) && /[A-Z]/.test(v) && /[!@#$%^&*)(+=._-]/.test(v) ) || this.$t("resetPasswordFromMail.passwordRulesInvalid"),
        min: v => (v && v.length >= 6) || this.$t('resetPasswordFromMail.pswdLength'),
        passwordMatch: () =>
          this.reNewPswd == this.newPswd || this.$t('resetPasswordFromMail.passwordNoMatch')
      },
      snackbar: {
        show: false,
        message: null,
        color: ""
      }
    };
  },
  
  created(){
    this.checkParameters();
  },
  
  methods: {

    ChangePassword: function() {

      //Schlüssel zum Profil
      var actionCode = this.$route.query.oobCode;
      var auth = firebase.auth();

      auth.verifyPasswordResetCode(actionCode).then(() => {
        
        var newPassword = this.newPswd;
        auth.confirmPasswordReset(actionCode, newPassword)
            .then(() => {
              this.$router.replace("login");

            }).catch((error) => {
              console.log(error);
              this.snackbar = {
                  show: true,
                  message: this.$t('resetPasswordFromMail.snackbar.internalError'),
                  color: "#DB4B1E"
              }
            });

      }).catch((error) => {
          console.log(error);
      });
    },

    checkParameters: function() {
      const lang = this.$route.query.lang.toString();
      const oobCode = this.$route.query.oobCode.toString();
      const mode = this.$route.query.mode.toString();

      console.log("mode: " + mode);

      if (mode == "recoverEmail"){
        console.log("Recover Mail, okay");
        this.recoverEmail();
      } else{
        this.$router.replace({
                name: "Reset_password_from_mail",
                params: { locale: lang },
                query:  { oobCode: oobCode }
        });
      }
    },

    recoverEmail: function(){
      console.log("Recover Mail, Test 2 bestanden");
      var actionCode = this.$route.query.oobCode;
      console.log("Action Code2: " + actionCode);

      var auth = firebase.auth();

      auth.checkActionCode(actionCode).then((info) => {
        console.log("Recover Mail, Test 3 bestanden");
        this.restoredEmail = info['data']['email'];
        return auth.applyActionCode(actionCode);
      }).then(() => {
        this.$router.replace("login");
      }).catch((error) => {
        console.log(error);
        this.snackbar = {
        show: true,
        message: this.$t('resetPasswordFromMail.snackbar.internalError'),
        color: "#DB4B1E"
        }
      });
    }
  }
};
</script>

<style>
@media all and (max-width: 600px) {
  .dummy {
    display: none !important;
  }
};
h1{
   word-break: normal;
}
</style>
