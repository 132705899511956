<template>
  <v-app>
    <v-card width="400" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1" v-t="$t('resetPassword.title')"></h1>
      </v-card-title>
      <v-card-text>
        <v-form ref="ResetPasswordForm" v-model="valid">
          <v-text-field
            v-model="email"
            v-bind:label="$t('resetPassword.email')"
            prepend-icon="mdi-account-circle"
            :rules="emailRules"
          />
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn to="/log-in" color="info" v-t="$t('resetPassword.backToLogin')">
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="reset" color="success" :disabled="!valid" v-t="$t('resetPassword.reset')">
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
import firebase from "firebase";
import i18n from "@/i18n";

export default {
  name: "Reset_password",
  data() {
    return {
      valid: false,
      email: "",
      emailRules: [
        v => !!v || this.$t('resetPassword.emailRulesRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('resetPassword.emailRulesValid')
      ],
    };
  },
  methods: {
    reset: function() {

      firebase.auth().languageCode = i18n.locale;
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'green',
           text: this.$t("resetPassword.mailSend")
          })
          this.$router.push('login');
        })
        .catch(function(error) {
          console.log(error);
          this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'red',
           text: this.$t("resetPassword.emailWrong")
          })
        });
    }
  }
};
</script>

<style scoped>
.login {
  margin-top: 40px;
}
input {
  margin: 10px 0;
  width: 20%;
  padding: 15px;
}

p {
  margin-top: 40px;
  font-size: 13px;
}
p a {
  text-decoration: underline;
  cursor: pointer;
}
</style>
