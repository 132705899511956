<template>
  <v-app>
    <v-card width="1600" class="mx-auto mt-5">
      <v-card-title>
      <h1 class="display-1">{{ $t('tOverview.title') }}</h1>
      <v-spacer/>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        v-bind:label="$t('tOverview.search')"
        single-line
        hide-details
      ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-container>
          
          <v-data-table
              :headers="headers"
              :items="tripInvoices"
              :search="search"
              :sort-by="'uploadDate'"
              :sort-desc="[false, true]"
              class="elevation-1"
          >
            <template v-slot:[`item.processed`]="{ item }">
                <v-chip :color="getItemColor(item.processed)">
                    {{ getItemStatusText(item.processed) }}
                </v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">

                <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                    <v-icon small class="mr-2" @click="showItem(item)" v-bind="attrs" v-on="on">mdi-magnify</v-icon>
                </template><span>{{$t("tOverview.tt_detail")}}</span></v-tooltip>

                <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                    <v-icon small @click="onClickDelete(item)" v-bind="attrs" v-on="on">mdi-delete</v-icon>
                </template><span>{{$t("tOverview.tt_delete")}}</span></v-tooltip>

<!--              <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                <v-icon small @click="onRemindUser(item)" v-bind="attrs" v-on="on">mdi-email</v-icon>
              </template><span>{{$t("tOverview.tt_remind")}}</span></v-tooltip>
-->

            </template>
          </v-data-table>
          <v-dialog v-model="dialog" max-width="600px">
                <v-card>
                    <v-card-title>
                        <span class="headline">{{ $t("tOverview.details") }}</span>
                        <v-spacer/>
                        <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                            <v-chip v-bind="attrs" v-on="on" :color="getStatusColor()" @click ="changeStatus()">
                                {{ getStatus() }}
                            </v-chip>
                        </template><span>{{$t("tOverview.tt_status")}}</span></v-tooltip>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-list name="shownItemList">
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-account-circle</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content >{{ $t('tOverview.name') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.ticketCreator}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                            <v-list-item-icon>
                                                <v-icon>mdi-gmail</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content >{{ $t('tOverview.email') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end"><a :href="'mailto:' + shownItem.email">{{shownItem.email}}</a></v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-lightbulb-on-outline</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.reason') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.reason}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item> 
                                            <v-list-item-icon>
                                                <v-icon>mdi-ray-start-arrow</v-icon>
                                            </v-list-item-icon>   
                                            <v-list-item-content>{{ $t('tOverview.start') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.startLocation}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>    
                                            <v-list-item-icon>
                                                <v-icon>mdi-ray-end</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>{{ $t('tOverview.end') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.endLocation}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-thought-bubble-outline</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.notes') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.additionalInfo}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-calendar</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.date') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.tripDate}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-format-list-bulleted-type</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.tripType') + ":" }}</v-list-item-content>
                                            <v-list-item-content v-if="shownItem.oneWay === true" class="align-end">One-way trip</v-list-item-content>
                                            <v-list-item-content v-if="shownItem.oneWay === false" class="align-end">Round trip</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item> 
                                            <v-list-item-icon>
                                                <v-icon>mdi-car</v-icon>
                                            </v-list-item-icon>   
                                            <v-list-item-content>{{ $t('tOverview.vehicle') + ":" }}</v-list-item-content>
                                            <v-list-item-content v-if="shownItem.byCar === true" class="align-end">Car</v-list-item-content>
                                            <v-list-item-content v-if="shownItem.byCar === false" class="align-end">Other</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item v-if="shownItem.receiptUrl!= undefined">
                                            <v-list-item-icon>
                                                <v-icon>mdi-camera</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>{{ $t('tOverview.receipt') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">
                                                <v-btn color="blue darken-1" text @click="downloadReceipt" >{{ $t('tOverview.showReceipt') }}</v-btn>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>    
                                            <v-list-item-icon>
                                                <v-icon>mdi-map-marker-distance</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>{{ $t('tOverview.distance') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.distance}} km</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item> 
                                            <v-list-item-icon>
                                                <v-icon>mdi-cash-multiple</v-icon>
                                            </v-list-item-icon>   
                                            <v-list-item-content>{{ $t('tOverview.predictedRefund') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.refundSum}} €</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-account-circle</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.bankAccountOwner') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.bankAccountName}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-bank</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.iban') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.iban}}</v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>  
                                            <v-list-item-icon>
                                                <v-icon>mdi-calendar</v-icon>
                                            </v-list-item-icon>  
                                            <v-list-item-content>{{ $t('tOverview.uploadDate') + ":" }}</v-list-item-content>
                                            <v-list-item-content class="align-end">{{shownItem.uploadDate}}</v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer/>
                        <v-btn color="blue darken-1" text @click="dialog = false" >{{ $t('tOverview.close') }}</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>  
        </v-container>
      </v-card-text>
    </v-card>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
        <v-card>
          <v-card-title
            class="headline"
          >
           {{ $t("tOverview.deleteHeadline") }}
          </v-card-title>
          <v-card-text>
            {{ $t("tOverview.deleteText") }}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="(deleteDialog = false), deleteItem()"
              v-t="$t('tOverview.delete')"
            ></v-btn>
            <v-btn
              text
              @click="deleteDialog = false"
              v-t="$t('tOverview.cancel')"
            ></v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="remindDialog" persistent max-width="290">
      <v-card>
        <v-card-title
            class="headline"
        >
          {{ $t("tOverview.remindHeadline") }}
        </v-card-title>
        <v-card-text>
          {{ $t("tOverview.remindText") }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="(remindDialog = false), remindUser()"
              v-t="$t('tOverview.remind')"
          ></v-btn>
          <v-btn
              text
              @click="remindDialog = false"
              v-t="$t('tOverview.cancel')"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="successfullyRemindDialog" persistent max-width="290">
      <v-card>
        <v-card-title
            class="headline"
        >
          {{ $t("tOverview.successfullyRemindText") }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              text
              @click="successfullyRemindDialog = false"
              v-t="$t('tOverview.ok')"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<script>
import {db, storage} from "../firebaseConfig.js";

export default {
    data: () => ({
        search: '',
        dialog: false,
        deleteDialog: false,
        remindDialog: false,
        successfullyRemindDialog: false,
        headers: [],
        deleteIndex: 0,
        shownIndex: -1,
        shownItem: {
            additionalInfo: '',
            byCar: false,
            distance: 0,
            endLocation: '',
            iban: '',
            id: '',
            oneWay: true,
            bankAccountName: '',
            reason: '',
            receiptUrl: '',
            refundSum: '',
            startLocation: '',
            ticketCreator: '',
            tripDate: '',
            uploadDate: '',
            email: '',
            processed: false,
        },
        tripInvoices: []
    }),
    created() {
        this.headers = [
            { text: this.$t('tOverview.status'), value: 'processed'},
            { text: this.$t('tOverview.uploadDate'), value: 'uploadDate'},
            { text: this.$t('tOverview.user'), value: 'ticketCreator'},
            { text: this.$t('tOverview.reason'), value: 'reason'},
            { text: this.$t('tOverview.start'), value: 'startLocation'},
            { text: this.$t('tOverview.end'), value: 'endLocation'},
            { text: this.$t('tOverview.date'), value: 'tripDate'},
            { text: this.$t('tOverview.actions'), value: 'actions', sortable: false }
        ];
        this.getData();
        
    },
    methods: {
        async getData() {
            var tripInvoiceArray = [];
            db.collection("TripInvoice")
                .get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        let entry = doc.data();
                        entry.id = doc.id;
                        tripInvoiceArray.push(entry);
                    });
            });
            this.tripInvoices = tripInvoiceArray;
        },
        showItem (item) {
            this.shownIndex = this.tripInvoices.indexOf(item);
            this.shownItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem () {
            const id = this.shownItem.id;
            this.tripInvoices.splice(this.deleteIndex, 1);
            db.collection("TripInvoice").doc(id).delete().then(function() {
                console.log("Document successfully deleted!");
            }).catch(function(error) {
                console.error("Error removing document: ", error);
            });
            // delete receipts
            let storageReference = storage.ref('TripInvoicePictures/' + this.shownItem.receiptUrl);
            storageReference.delete().then(function() {
                console.log("Receipt deleted.");
            }).catch(function(error) {
                console.log(error);
            });
        },
        remindUser() {
          //Email an Nutzer schicken
          this.successfullyRemindDialog = true;
        },
        downloadReceipt () {
            let storageReference = storage.ref("TripInvoicePictures/" + this.shownItem.receiptUrl);
            console.log(storageReference);
            storageReference.getDownloadURL().then(function(url) {
                var xhr = new XMLHttpRequest();
                xhr.responseType = 'blob';
                xhr.onload = function() {
                    var blob = xhr.response;
                    let suffixName = '';
                    switch(blob.type) {
                        case 'image/jpeg':
                            suffixName = '.jpg';
                            break;
                        case 'image/png':
                            suffixName = '.png';
                            break;
                        case 'image/bmp':
                            suffixName = '.bmp';
                            break;
                        case 'application/pdf':
                            suffixName = '.pdf';
                            break;
                        default:
                            break;
                    }
                    var link = document.createElement('a');
                    link.href = xhr.responseURL;
                    link.download = "receipt" + suffixName;
                    window.open(link);
                };
                xhr.open("GET", url);
                xhr.send();
            }).catch(function() {
                console.log('something went wrong...');
            });
        },
        //returns color of the shownItem
        getStatusColor () {
            let color = 'gray';
            if(this.shownItem.processed === false) {
                color = 'orange';
            } else if (this.shownItem.processed === true) {
                color = 'green';
            }
            return color;
        },
        getItemColor (itemStatus) {
            console.log('updated');
            let color = 'gray';
            if(itemStatus === false) {
                color = 'orange';
            } else if (itemStatus === true) {
                color = 'green';
            }
            return color;
        },
        getItemStatusText (itemStatus) {
            if(itemStatus === false) {
                return this.$t('tOverview.pending');
            }else if(itemStatus === true) {
                return this.$t('tOverview.processed');
            }else {
                return this.$t('tOverview.undefined');
            }
        },
        getStatus () {
            if(this.shownItem.processed === false) {
                return this.$t('tOverview.pending');
            }else if(this.shownItem.processed === true) {
                return this.$t('tOverview.processed');
            }else {
                return this.$t('tOverview.undefined');
            }
        },
        changeStatus () {
            if(this.shownItem.processed === true) {
                this.shownItem.processed = false;
            }else {
                this.shownItem.processed = true;
            }
            db.collection("TripInvoice")
                .doc(this.shownItem.id)
                .update({
                    processed: this.shownItem.processed
                })
                .catch(() => {
                    console.log('Could not update Status!');
                }).then(this.getData);
        },
        onClickDelete (item) {
            this.deleteDialog = true;
            this.shownItem = item;
            this.deleteIndex = this.tripInvoices.indexOf(item);
        },
        onRemindUser (item) {
            this.remindDialog = true;
            this.shownItem = item;
            this.remindIndex = this.acquisitionInvoices.indexOf(item);
      }
    }
}
</script>