<template>
  <v-app>
    <v-card width="800" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1" v-t="'acInvoice.title'"></h1>
      </v-card-title>
      <v-card-text>
        <v-form v-model="formValidation" ref="acquisitionForm" lazy-validation>
          <v-container v-for="(invoice, index) in invoices" :key="index">
            <v-row>
              <v-col>
                <span v-t='"acInvoice.invoiceNrText"'></span>
                <span>{{index + 1}}</span>
                <span
                  class="float-right"
                  style="cursor:pointer"
                  @click="deleteForm(index)"
                >
                
                  <template v-if="index == 0">
                    <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-delete-off</v-icon>
                    </template><span>{{$t("acInvoice.tt_delete")}}</span></v-tooltip>
                    </template>
                  <template v-else>
                    <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-delete</v-icon>
                    </template><span>{{$t("acInvoice.tt_delete")}}</span></v-tooltip>
                  </template>
                  
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="invoice.itemName"
                  prepend-icon="mdi-pencil"
                  v-bind:label="$t('acInvoice.itemName')"
                  :counter="20"
                  v-bind:rules="[v => !!v || $t('acInvoice.itemNameRuleOne')]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="invoice.itemPrice"
                  prepend-icon="mdi-cash-multiple"
                  type="number"
                  suffix="€"
                  v-bind:label="$t('acInvoice.itemPrice')"
                  min="0.00"
                  v-bind:rules="[v => !!v || $t('acInvoice.itemPriceRuleOne'),
                                   v => v >= 0 || $t('acInvoice.priceRule')]"
                  @change="refreshTotalSum"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="invoice.purchasePurpose"
                  prepend-icon="mdi-lightbulb-on-outline"
                  v-bind:label="$t('acInvoice.purchasePurpose')"
                  :counter="50"
                  v-bind:rules="[
                    v => !!v || $t('acInvoice.purchasePurposeRuleOne')
                  ]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="invoice.additionalInformation"
                  v-bind:label="$t('acInvoice.additionalInformation')"
                  prepend-icon="mdi-thought-bubble-outline"
                  outlined
                  :counter="500"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-file-input
                  ref="fileInput{{index}}"
                  type="file"
                  accept=".png,.jpeg,.jpg,.pdf"
                  v-model="invoice.selectedFile"
                  @change="onFileUpload($event, index)"
                  v-bind:label="$t('acInvoice.fileUpload')"
                  prepend-icon="mdi-camera"
                  v-bind:rules="[
                    v => !!v || $t('acInvoice.fileUploadRuleOne'),
                    v =>
                      !v ||
                      v.size < 10000000 ||
                      $t('acInvoice.fileUploadRuleTwo')
                  ]"
                />
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-container>
          <v-container>
            <v-row>
              <v-col>
                <v-card-actions>
                  <v-spacer />
                  <span
                    class="float-right"
                    style="cursor:pointer"
                    @click="addInvoiceForm()"
                  >
                    <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on">mdi-book-plus-multiple-outline</v-icon>
                    </template><span>{{$t("acInvoice.tt_addDoc")}}</span></v-tooltip>
                  </span>
                </v-card-actions>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-checkbox
                  v-bind:label="$t('acInvoice.bankDataFromProfile')"
                  :disabled="!this.user.IBAN"
                  prepend-icon="mdi-account-check-outline"
                  v-model="defaultBankAccount"
                  @change="onOtherBankAccountSelect()"
                ></v-checkbox>
              </v-col>
              <v-tooltip v-if="this.disableButton" bottom class="tooltip_button">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" dark v-on="on" class="tooltip_button"
                    >mdi-information-outline
                  </v-icon>
                </template>
                <span v-t="'acInvoice.employmentInformation'" />
              </v-tooltip>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="bankAccountName"
                  :disabled="this.disableInputField"
                  prepend-icon="mdi-account-circle"
                  v-bind:label="$t('acInvoice.bankAccount')"
                  v-bind:rules="bankAccountRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-mask="'AA## NNNN NNNN NNNN NNNN NNNN NNN?N NNN?N'"
                  v-model="bankAccountIBAN"
                  :disabled="this.disableInputField"
                  prepend-icon="mdi-bank"
                  v-bind:label="$t('acInvoice.iban')"
                  v-bind:rules="ibanRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="totalRefundSum"
                  :disabled="true"
                  v-bind:label="$t('acInvoice.refundSum')"
                  prepend-icon="mdi-cash-multiple"
                  suffix="€"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-col>
            <span v-t='"acInvoice.required"'></span>
        </v-col>
        <v-spacer />
        <v-btn
          @click="submit"
          :disabled="!formValidation"
          v-t="'acInvoice.submit'"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <v-row>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title
            class="headline"
            v-t="'acInvoice.dialogHeadline'"
          ></v-card-title>
          <v-card-text v-t="'acInvoice.dialogBody'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="(dialog = false), updateUserProfileBankData()"
              v-t="'acInvoice.dialogButtonOne'"
            ></v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="(dialog = false), resetForm()"
              v-t="'acInvoice.dialogButtonTwo'"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="originalReceiptsDialog" persistent max-width="290">
        <v-card>
          <v-card-title
              class="headline"
              v-t="'acInvoice.originalReceiptsDialogHeadline'"
          ></v-card-title>
          <v-card-text v-t="'acInvoice.originalReceiptsDialogBody'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="(originalReceiptsDialog = false)"
                v-t="'acInvoice.originalReceiptsDialogButton'"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>
<script>
import {db, storage} from "../firebaseConfig.js";
import firebase from "./../firebaseConfig";
import {validateIban} from "../util/validation/iban-validation";
import {validateBankAccountName} from "../util/validation/bank-account-validation";

export default {

  data() {
    return {
      formValidation: true,
      invoices: [
        {
          itemName: "",
          itemPrice: null,
          purchasePurpose: "",
          additionalInformation: "",
          selectedFile: null,
          pictureUrl: ""
        }
      ],
      defaultBankAccount: false,
      bankAccountName: "",
      bankAccountIBAN: "",
      timestamp: "",
      totalRefundSum: 0,
      userEmail: firebase.auth().currentUser.email,
      user: {},
      dialog: false,
      originalReceiptsDialog: false,
      disableInputField: false,
      disableButton: true,
      bankAccountRules: [
        v => !!v || this.$t('tInvoice.required'),
        v => validateBankAccountName(v) || 'incorrect bank account name (only letters)'
      ],
      ibanRules: [
        v => !!v || this.$t('acInvoice.ibanRuleOne'),
        v => validateIban(v.replace(/\s/g, '')) || this.$t('acInvoice.ibanRuleTwo')
      ]
    };
  },

  created() {
    this.getUser();
  },
  methods: {
    // control input fields after activate checkbox "use bank data"
    onOtherBankAccountSelect: function() {
      if (this.defaultBankAccount) {
        this.bankAccountName = this.user.bankAccountName;
        this.bankAccountIBAN = this.user.IBAN;
        this.disableInputField = true;
        this.disableButton = false;
      } else {
        console.log("Keine Bankdaten");
        this.bankAccountName = "";
        this.bankAccountIBAN = "";
        this.disableInputField = false;
        this.disableButton = true;
      }
    },
    onFileUpload(file, index) {
      if(file!=null){
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.addEventListener("load", () => {
        this.invoices[index].selectedFile = file;
      });
      }
    },
    submit() {
      if (this.invoices.length > 0) {
        if (this.$refs.acquisitionForm.validate()) {
          this.timestamp = Date.now();
          this.uploadPicture();
          this.uploadDocument();
          if (
            this.user.bankAccountName != this.bankAccountName ||
            this.user.IBAN != this.bankAccountIBAN
          ) {
            this.dialog = true;
            this.originalReceiptsDialog = true;
          } else {
            this.resetForm();
            this.originalReceiptsDialog = true;
          }
        } else {
          this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'red',
           text: this.$t("acInvoice.snackbarTextOne")
         })
        }
      } else {
        this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'red',
           text: this.$t("acInvoice.snackbarTextTwo")
         })
      }
    },
    uploadPicture() {
      this.invoices.forEach(invoice => {
        let file = invoice.selectedFile;
        let storageReference = storage.ref(
          "AcquisitionInvoicePictures/" + this.timestamp + file.name
        );
        let task = storageReference.put(file);

        task.then(() => {
          this.$store.dispatch('setSnackbar', {
            showing: true,
            color: 'green',
            text: this.$t("acInvoice.snackbarTextThree")
          })
        });
      });
    },

    uploadDocument() {
      this.invoices.forEach(invoice => {
        let file = invoice.selectedFile;
        db.collection("AcquisitionInvoice")
          .add({
            itemName: invoice.itemName,
            itemPrice: invoice.itemPrice,
            purchasePurpose: invoice.purchasePurpose,
            additionalInformation: invoice.additionalInformation,
            bankAccountName: this.bankAccountName,
            bankAccountIBAN: this.bankAccountIBAN,
            receiptUrl: this.timestamp + file.name,
            ticketCreator: this.user.firstName + " " + this.user.lastName,
            timestamp: this.getDate(),
            email: this.user.email,
            processed: false
          })
          .catch(() => {
            this.$store.dispatch('setSnackbar', {
              showing: true,
              color: 'red',
              text: this.$t("acInvoice.snackbarTextFour")
            })
          });
      });
    },
    addInvoiceForm() {
      this.invoices.push({
        itemName: "",
        itemPrice: null,
        purchasePurpose: "",
        additionalInformation: "",
        selectedFile: null
      });
    },
    deleteForm(index) {
      if(index!=0){
        this.invoices.splice(index, 1);
      }
    },
    refreshTotalSum() {
      this.totalRefundSum = 0.0;
      this.invoices.forEach(invoice => {
        this.totalRefundSum = (
          parseFloat(this.totalRefundSum) + parseFloat(invoice.itemPrice)
        ).toFixed(2);
      });
    },
    getUser() {
      db.collection("User")
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then(doc => {
          this.user = {
            id: doc.id,
            ...doc.data()
          };
        });
    },
    updateUserProfileBankData() {
      db.collection("User")
        .doc(this.user.id)
        .update({
          bankAccountName: this.bankAccountName,
          IBAN: this.bankAccountIBAN
        });
      this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'green',
           text: this.$t("tInvoice.addedBankAccount")
      })
      this.resetForm();
      this.getUser();
    },
    resetForm() {
      this.$refs.acquisitionForm.reset();
    },
    getDate() {
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth()+1;
      var yyyy = today.getFullYear();
      if(dd<10)
      {
          dd='0'+dd;
      }

      if(mm<10)
      {
          mm='0'+mm;
      }
      return yyyy+'-'+mm+'-'+dd;
    }
  }
};
</script>

<style scope>
.tooltip_button {
  margin-right: 1em;
}
</style>
