import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state:{
        snackbars: [],
        isAdmin: false
    },
    mutations: {
        SET_SNACKBAR(state, snackbar){
            state.snackbars = state.snackbars.concat(snackbar);
        },
        SET_ADMIN(state){
            state.isAdmin = true;
        },
        RESET_ADMIN(state){
            state.isAdmin = false;
        }
    },
    actions:{
        setSnackbar({commit}, snackbar){
            snackbar.showing = true;
            snackbar.color = snackbar.color || 'success';
           commit('SET_SNACKBAR', snackbar); 
        },
        setAdmin(){
            store.commit('SET_ADMIN');
        },
        resetAdmin(){
            store.commit('RESET_ADMIN')
        }
    }
})