import IBAN from "iban";

/**
 * Validates the users iban and does not accept whitespaces.
 * The string should not contain whitespaces. For better readability, we should not include whitespaces
 * in the string but rather use a mask. (https://github.com/probil/v-mask)
 * This method even checks the iban's check sum for validity. Therefore,
 * accidentally entering a wrong IBAN is almost impossible.
 * @param iban the entered value
 * @return {boolean} whether the iban is valid
 */
export function validateIban(iban) {
  return iban && !iban.includes(' ') && IBAN.isValid(iban);
}
