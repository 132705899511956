import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyAzZ5lcCbjbYGJk_ykVxOJEeb2aMZ7nhOk",
    authDomain: "ehrenamtlichenmanagement-935b2.firebaseapp.com",
    databaseURL: "https://ehrenamtlichenmanagement-935b2.firebaseio.com",
    projectId: "ehrenamtlichenmanagement-935b2",
    storageBucket: "ehrenamtlichenmanagement-935b2.appspot.com",
    messagingSenderId: "649998262880",
    appId: "1:649998262880:web:e9fa32b721fc7d00885905"
};

require("firebase/firestore");
require("firebase/functions");

firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.app().functions('europe-west1');

export default firebase;