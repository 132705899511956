<template>
  <v-app>
    <v-card width="1600" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1">{{ $t("userOverview.title") }}</h1>
        <v-spacer />
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          v-bind:label="$t('userOverview.search')"
          single-line
          hide-details
        ></v-text-field>
        <v-btn icon color="darkgrey">
          <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" x-large class="mr-2" @click="onClickFilter()">mdi-filter</v-icon>
          </template><span>{{$t("userOverview.tt_filter")}}</span></v-tooltip>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-data-table
            v-model="selected"
            :headers="headers"
            :items="users"
            :search="search"
            :sort-desc="[false, true]"
            class="elevation-1"
            :item-class="rowStyle"
            show-select
          >
            <template v-slot:[`item.actions`]="{ item }">

              <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" @click="userExiting(item)" v-if="!item.exitDate" v-bind="attrs" v-on="on">mdi-account-off</v-icon>
              </template><span>{{$t("userOverview.tt_inactive")}}</span></v-tooltip>

              <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" @click="userExiting(item)" v-if="item.exitDate" v-bind="attrs" v-on="on">mdi-account</v-icon>
              </template><span>{{$t("userOverview.tt_active")}}</span></v-tooltip>

              <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                  <v-icon class="mr-2" @click="showItem(item)" v-bind="attrs" v-on="on">mdi-magnify</v-icon>
              </template><span>{{$t("userOverview.tt_details")}}</span></v-tooltip>

              <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" @click="onClickDelete(item)" v-bind="attrs" v-on="on">mdi-delete</v-icon>
              </template><span>{{$t("userOverview.tt_delete")}}</span></v-tooltip>

              <v-tooltip bottom><template v-slot:activator="{ on, attrs }">
                <v-icon class="mr-2" @click="changeAdmin(item)" v-bind="attrs" v-on="on">mdi-account-cog</v-icon>
              </template><span>{{$t("userOverview.tt_admin")}}</span></v-tooltip>
            </template>
            <template v-slot:[`item.admin`]="{ item }">
              <v-icon>
                {{ setAdminIcon(item.admin) }}
              </v-icon>
          </template>
          </v-data-table>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span
                  class="headline"
                  v-text="$t('userOverview.details')"
                ></span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col>
                      <v-list name="shownUserList">
                        <v-list-item>
                          <v-list-item-icon >
                            <v-icon>mdi-account-circle</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("userOverview.firstname") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownUser.firstName
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-account-circle</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("userOverview.lastname") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownUser.lastName
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-gmail</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("userOverview.email") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <a :href="'mailto:' + shownUser.email">{{
                              shownUser.email
                            }}</a>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon class="icon">mdi-home-variant</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            {{ $t("userOverview.address") + ":" }}
                          </v-list-item-content>
                          <v-list-item-content class="align-end">
                            {{ shownUser.street }}
                            <br />
                            {{ shownUser.zipCode }}
                            <br />
                            {{ shownUser.city }}
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-cake</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("userOverview.birthdate") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            formatDate(shownUser.birthDate)
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-map-marker</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("userOverview.birthplace") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownUser.birthPlace
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-human-male-female</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("userOverview.gender") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownUser.gender
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-briefcase</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("userOverview.employment") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownUser.employment
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-account-outline</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("userOverview.reichsregisternumber") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownUser.reichsregisterNumber
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon>mdi-card-account-details</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("userOverview.identificationnumber") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">{{
                            shownUser.identificationNumber
                          }}</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon >
                            <v-icon class="icon-calendar">mdi-calendar</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("userOverview.entryDate") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content
                            id="v-list-item-content-entry-date"
                            class="align-end"
                          >
                            <v-dialog
                              v-model="entryDateDialog"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="shownUser.entryDate"
                                  prepend-icon="mdi-calendar"
                                  v-bind:label="$t('userOverview.entryDate')"
                                  v-on="on"
                                  required
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="shownUser.entryDate"
                                :max="today"
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="entryDateDialog = false"
                                  >{{ $t("userOverview.ok") }}</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon class="icon-calendar">mdi-calendar</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>{{
                            $t("userOverview.exitDate") + ":"
                          }}</v-list-item-content>
                          <v-list-item-content class="align-end">
                            <v-dialog
                              v-model="exitDateDialog"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-model="shownUser.exitDate"
                                  prepend-icon="mdi-calendar"
                                  v-bind:label="$t('userOverview.exitDate')"
                                  v-on="on"
                                  required
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="shownUser.exitDate"
                                :min="today"
                                scrollable
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="exitDateDialog = false"
                                  >{{ $t("userOverview.ok") }}</v-btn
                                >
                              </v-date-picker>
                            </v-dialog>
                          </v-list-item-content>
                          <v-icon @click="deleteExitDate(shownUser.id)"
                            >mdi-delete</v-icon
                          >
                        </v-list-item>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">{{
                  $t("userOverview.close")
                }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-divider></v-divider>
        </v-container>
        <vue-json-to-csv
          :json-data="selected"
          :csv-title="'vrijwilligers'"
          :separator="';'"
        >
          <v-btn>
            <b>{{ $t("userOverview.downloadSelected") }}</b>
          </v-btn>
        </vue-json-to-csv>
        <vue-json-to-csv
          :json-data="users"
          :csv-title="'vrijwilligers'"
          :separator="';'"
        >
          <v-btn class="ml-5">
            <b>{{ $t("userOverview.downloadAll") }}</b>
          </v-btn>
        </vue-json-to-csv>
      </v-card-text>
    </v-card>
    <v-dialog v-model="deleteDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ $t("userOverview.deleteHeadline") }}
        </v-card-title>
        <v-card-text>{{ $t("userOverview.deleteText") }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="(deleteDialog = false), deleteItem()"
            v-t="$t('userOverview.delete')"
          ></v-btn>
          <v-btn
            text
            @click="deleteDialog = false"
            v-t="$t('userOverview.cancel')"
          ></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="filterDialog" persistent max-width="290">
      <v-card>
        <v-card-title class="headline">
          {{ $t("userOverview.filterHeadline") }}
        </v-card-title>
        <v-card-text>
          {{ $t("userOverview.userStatusFilter") }}
              <v-checkbox
                  v-model="cbActiveUser"
                  v-bind:label="$t('userOverview.userStatusActiveFilter')"
                  hide-details
                  prepend-icon="mdi-account"
                ></v-checkbox>
              <v-checkbox
                v-model="cbInactiveUser"
                v-bind:label="$t('userOverview.userStatusInactiveFilter')"
                hide-details
                prepend-icon="mdi-account-off"
              ></v-checkbox>   
              <div v-show="cbActiveUser==false && cbInactiveUser==false" v-t="$t('userOverview.filterInvalid')">
              </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="applyFilters()"
            v-t="$t('userOverview.applyFilters')"
            color="green"
          ></v-btn>
          <v-btn
            text
            @click="filterDialog = false"
            v-t="$t('userOverview.cancel')"
          ></v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import {db, functions} from "../firebaseConfig.js";
import moment from "moment";
import firebase from "../firebaseConfig";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  data: () => ({
    search: "",
    dialog: false,
    deleteDialog: false,
    deleteIndex: 0,
    filterDialog: false,
    cbActiveUser:true,
    cbInactiveUser:true,
    entryDateDialog: false,
    exitDateDialog: false,
    today: new Date().toISOString().substr(0, 10),
    users: [],
    selected: [],
    options: {
      year: "numeric",
      month: "short",
      day: "2-digit"
    },
    shownIndex: -1,
    shownUser: {}
  }),
  components: {
    VueJsonToCsv
  },
  created() {
    (this.headers = [
      { text: this.$t("userOverview.firstname"), value: "firstName" },
      { text: this.$t("userOverview.lastname"), value: "lastName" },
      { text: this.$t("userOverview.admin"), value: "admin" },
      {
        text: this.$t("userOverview.actions"),
        value: "actions",
        sortable: false
      }
    ]),
      this.getUsers();
  },
  watch: {
    dialog: function(newVal) {
      if (!newVal) {
        this.saveUser();
      }
    }
  },
  methods: {
    async getUsers() {
      if(this.cbActiveUser === false){
        this.getInactiveUsers();
      }else if(this.cbInactiveUser === false){
        this.getActiveUsers();
      }else{
      var usersArray = [];
      db.collection("User")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let entry = doc.data();
            entry.id = doc.id;
            usersArray.push(entry);
          });
        });
      this.users = usersArray;
      }
    },
    async getActiveUsers() {
      var usersArray = [];
      db.collection("User")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let entry = doc.data();
            if("exitDate" in entry === false){
              entry.id = doc.id;
              usersArray.push(entry);
            }
          });
        });
      this.users = usersArray;
    },
    async getInactiveUsers() {
      var usersArray = [];
      db.collection("User")
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            let entry = doc.data();
            if("exitDate" in entry){
              entry.id = doc.id;
              usersArray.push(entry);
            }
          });
        });
      this.users = usersArray;
    },
    saveUser: function() {
      if (this.shownUser.entryDate) {
        db.collection("User")
          .doc(this.shownUser.id)
          .update({
            entryDate: this.shownUser.entryDate
          })
          .then(() => {
            this.getUsers();
          });
      }
      if (this.shownUser.exitDate) {
        db.collection("User")
          .doc(this.shownUser.id)
          .update({
            exitDate: this.shownUser.exitDate
          })
          .then(() => {
            this.getUsers();
          });
      }
    },
    formatDate: function(date) {
      try {
        const dateObject = new Date(date);
        return dateObject.toLocaleDateString("en", this.options);
      } catch (error) {
        console.error("Invalid Date Format: " + error);
      }
    },
    setAdminIcon(itemAdmin) {
      if (itemAdmin == true) {
        return "mdi-check";
      } else {
        return;
      }
    },
    userExiting(user) {
      if (!user.exitDate) {
        const id = user.id;
        var currentDate = new Date();
        var formatedDate = moment(String(currentDate)).format("YYYY-MM-DD");
        db.collection("User")
          .doc(id)
          .update({
            exitDate: formatedDate
          })
          .then(() => {
            this.getUsers();
          });
      } else {
        this.deleteExitDate(user.id);
      }
    },
    deleteExitDate(userID) {
      db.collection("User")
        .doc(userID)
        .update({
          exitDate: firebase.firestore.FieldValue.delete()
        })
        .then(() => {
          this.shownUser.exitDate = undefined;
          this.getUsers();
        });
    },
    showItem(item) {
      this.shownIndex = this.users.indexOf(item);
      this.shownUser = Object.assign({}, item);
      this.dialog = true;
    },
    deleteItem() {
      const id = this.shownUser.id;
      this.users.splice(this.deleteIndex, 1);
      db.collection("User")
        .doc(id)
        .delete()
        .then(() => {
          var deleteUserFunction = functions.httpsCallable("deleteUser");
          deleteUserFunction({ uid: id });
          console.log("User successfully deleted!");
        })
        .catch(function(error) {
          console.error("Error removing user: ", error);
        });
    },
    onClickDelete(item) {
      this.deleteDialog = true;
      this.shownUser = item;
      this.deleteIndex = this.users.indexOf(item);
    },
    onClickFilter() {
      this.filterDialog = true;
    },
    applyFilters(){
      if(this.cbActiveUser === false && this.cbInactiveUser === false){
        return;
      }else if(this.cbActiveUser === true && this.cbInactiveUser === true){
        this.getUsers();
      }else if(this.cbActiveUser === true){
        this.getActiveUsers();
      }else if(this.cbInactiveUser === true){ 
        this.getInactiveUsers();
      }
      this.filterDialog = false;
    },
    changeAdmin(item){
      this.shownUser = item;
      db.collection("User")
          .doc(this.shownUser.id)
          .update({
            admin: !this.shownUser.admin
          })
          .then(() => {
            this.getUsers();
          });
    },
    rowStyle(user) {
      if (typeof user.exitDate !== "undefined") {
        return "user-exited";
      } else {
        return "";
      }
    }
  }
};
</script>

<style>
.user-exited {
  color: #cfcfcf;
}

#v-list-item-content-entry-date {
  margin-right: 24px !important;
}

.icon {
  margin-top: 16px;
}

.icon-calendar {
  margin-top: 19px;
}
</style>