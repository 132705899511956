<template>
  <v-app>
    <v-card width="800" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1" v-t="'tInvoice.title'"></h1>
      </v-card-title>
      <v-card-text>
        <v-form ref="tripInvoiceForm" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="startLocation"
                  v-bind:label="$t('tInvoice.startLocation')"
                  prepend-icon="mdi-ray-start-arrow"
                  :rules="[v => !!v || $t('tInvoice.required')]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="endLocation"
                  v-bind:label="$t('tInvoice.destination')"
                  prepend-icon="mdi-ray-end"
                  :rules="[v => !!v || $t('tInvoice.required')]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <!-- add length validation -->
                <v-text-field
                  v-bind:label="$t('tInvoice.reason')"
                  v-model="reason"
                  prepend-icon="mdi-lightbulb-on-outline"
                  :counter="50"
                  :rules="[v => !!v || $t('tInvoice.required')]"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  v-model="additionalInfo"
                  prepend-icon="mdi-thought-bubble-outline"
                  outlined
                  name="input-7-4"
                  v-bind:label="$t('tInvoice.notes')"
                  :counter="500"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="tripDate"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="tripDate"
                      prepend-icon="mdi-calendar"
                      v-bind:label="$t('tInvoice.date')"
                      v-on="on"
                      tabindex="-1"
                      :rules="[v => !!v || $t('tInvoice.required')]"
                      required
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="tripDate" scrollable>
                    <v-spacer></v-spacer>
                     <v-btn
                          text
                          color="primary"
                          @click="modal = false"
                          >Cancel</v-btn
                        >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(tripDate)"
                      >{{ $t("tInvoice.ok") }}</v-btn
                    >
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="checkboxCar"
                  v-bind:label="$t('tInvoice.byCar')"
                  prepend-icon="mdi-car"
                  @change="onByCarChanged"
                ></v-checkbox>
              </v-col>
            </v-row>
            <div v-if="checkboxCar">
              <v-row>
                <v-col>
                  <v-text-field
                    @input="calculateRefund"
                    v-model="drivenDistance"
                    v-bind:label="$t('tInvoice.drivenDistance')"
                    prepend-icon="mdi-map-marker-distance"
                    suffix="km"
                    min="0"
                    :rules="[v => !!v || $t('tInvoice.required'), 
                              v => v >= 0 || $t('tInvoice.greaterzeroRule')]"
                    type="number"
                  ></v-text-field>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <v-row>
                <v-col>
                  <v-file-input
                    type="file"
                    accept=".jpg, .jpeg, .png, .pdf"
                    v-model="chosenFile"
                    v-bind:label="$t('tInvoice.uploadInvoice')"
                    @change="onUpload"
                    prepend-icon="mdi-camera"
                    :rules="[
                      v =>
                        !v || v.size < 10000000 || $t('tInvoice.fileUploadRule')
                    ]"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="refundSum"
                  v-bind:label="$t('tInvoice.refund')"
                  prepend-icon="mdi-cash-multiple"
                  suffix="€"
                  :rules="[v => !!v || $t('tInvoice.required'),
                            v => v >= 0 || $t('tInvoice.greaterzeroRule')]"
                  type="number"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col>
                <v-checkbox
                  v-model="checkboxUseProfile"
                  :disabled="!this.user.IBAN"
                  v-bind:label="$t('tInvoice.useBankData')"
                  prepend-icon="mdi-account-check-outline"
                  @change="onUseProfileChanged()"
                ></v-checkbox>
              </v-col>
              <v-tooltip v-if="this.disableButton" bottom class="tooltip_button">
                <template v-slot:activator="{ on }">
                  <v-icon color="primary" dark v-on="on" class="tooltip_button">
                    mdi-information-outline 
                  </v-icon>
                </template>
                <span v-t="'tInvoice.employmentInformation'" />
              </v-tooltip>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="bankAccountName"
                  :disabled="this.disableInputField"
                  v-bind:label="$t('tInvoice.bankAccountName')"
                  prepend-icon="mdi-account-circle"
                  :rules="bankAccountRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  v-mask="'AA## NNNN NNNN NNNN NNNN NNNN NNN?N NNN?N'"
                  v-model="iban"
                  :disabled="this.disableInputField"
                  v-bind:label="$t('tInvoice.iban')"
                  prepend-icon="mdi-bank"
                  :rules="ibanRules"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row> </v-row>
          </v-container>
          
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
         <v-col>
            <span v-t='"tInvoice.required"'></span>
         </v-col>
        <v-spacer />
        <v-btn
          v-on:click="send"
          :disabled="!valid"
          v-t="'tInvoice.submit'"
        ></v-btn>
      </v-card-actions>
    </v-card>
    <v-row>
      <v-dialog v-model="dialog" persistent max-width="290">
        <v-card>
          <v-card-title
            class="headline"
            v-t="'tInvoice.dialogHeadline'"
          ></v-card-title>
          <v-card-text v-t="'tInvoice.dialogBody'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="(dialog = false), addToProfileDialog(true)"
              v-t="$t('tInvoice.yes')"
            ></v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="(dialog = false), addToProfileDialog(false)"
              v-t="$t('tInvoice.no')"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="originalReceiptsDialog" persistent max-width="290">
        <v-card>
          <v-card-title
              class="headline"
              v-t="'tInvoice.originalReceiptsDialogHeadline'"
          ></v-card-title>
          <v-card-text v-t="'tInvoice.originalReceiptsDialogBody'"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="(originalReceiptsDialog = false)"
                v-t="'tInvoice.originalReceiptsDialogButton'"
            ></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-app>
</template>

<!-- <script src="../trip_invoice.js"></script> -->
<script>
import {db, storage} from "../firebaseConfig.js";
import firebase from "./../firebaseConfig";
import {validateIban} from "../util/validation/iban-validation";
import {validateBankAccountName} from "../util/validation/bank-account-validation";


export default {
  data() {
    return {
      startLocation: "",
      endLocation: "",
      modal: false,
      date: null,
      tripDate: null,
      reason: "",
      additionalInfo: "",
      valid: true,
      checkboxCar: true,
      checkboxUseProfile: false,
      drivenDistance: null,
      travelType: 0,
      oneway: true,
      roundtrip: false,
      refundSum: null,
      chosenFile: null,
      bankAccountName: "",
      iban: "",
      userEmail: firebase.auth().currentUser.email,
      user: {},
      dialog: false,
      originalReceiptsDialog: false,
      disableInputField: false,
      disableButton: true,
      bankAccountRules: [
        v => !!v || this.$t('tInvoice.required'),
        v => validateBankAccountName(v) || 'incorrect bank account name (only letters)'
      ],
      ibanRules: [
        v => !!v || this.$t('tInvoice.required'),
        v => validateIban(v.replace(/\s/g, '')) || this.$t('tInvoice.ibanRule')
      ]
    };
  },
  created() {
    this.getUser();
  },
  methods: {
    validate() {
      return this.$refs.tripInvoiceForm.validate();
    },
    //get user data for autocomplete bank account information
    getUser() {
      db.collection("User")
        .doc(firebase.auth().currentUser.uid)
        .get()
        .then(doc => {
          this.user = {
            id: doc.id,
            ...doc.data()
          };
          console.log("in getUser():" + this.user.IBAN)
        });
      return true;
    },
    resetForm() {
      this.$refs.tripInvoiceForm.reset();
    },

    // calculate the refund sum based on the distance (distance * 0.19 euros)

    calculateRefund: function() {
      let amount = this.drivenDistance * 0.19;
      this.refundSum = amount.toFixed(2);
    },
    // control input fields after activate checkbox "use bank data"
    onUseProfileChanged: function() {
      if (this.checkboxUseProfile) {
        this.bankAccountName = this.user.bankAccountName;
        this.iban = this.user.IBAN;
        this.disableInputField = true;
        this.disableButton = false;
      } else {
        this.bankAccountName = "";
        this.iban = "";
        this.disableInputField = false;
        this.disableButton = true;
      }
    },
    onByCarChanged: function(){
      if(this.checkboxCar){
        this.calculateRefund();
      } else {
        this.refundSum = null;
      }
    },
    onUpload(file) {
      const fr = new FileReader();
      fr.readAsDataURL(file);
      fr.addEventListener("load", () => {
        this.chosenFile = file;
      });
    },
    uploadInvoiceReceipt() {
      let storageReference = storage.ref(
        "TripInvoicePictures/" +
          this.timestamp +
          "_" +
          this.user.firstName +
          "_" +
          this.user.lastName
      );
      storageReference.put(this.chosenFile);
    },
    writeToDatabase() {
      let drivenoneway;
      if (this.travelType == 0) {
        drivenoneway = true;
      } else {
        drivenoneway = false;
      }
      let receiptUrl = null;
      if (!this.checkboxCar) {
        receiptUrl =
          this.timestamp + "_" + this.user.firstName + "_" + this.user.lastName;
      }
      db.collection("TripInvoice")
        .add({
          ticketCreator: this.user.firstName + " " + this.user.lastName,
          startLocation: this.startLocation,
          endLocation: this.endLocation,
          tripDate: this.tripDate,
          reason: this.reason,
          additionalInfo: this.additionalInfo,
          byCar: this.checkboxCar,
          distance: this.drivenDistance,
          refundSum: this.refundSum,
          oneWay: drivenoneway,
          bankAccountName: this.bankAccountName,
          iban: this.iban,
          receiptUrl: receiptUrl,
          uploadDate: this.getDate(),
          email: this.user.email,
          processed: false
        })
        .catch(() => {
         this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'red',
           text: this.$t("tInvoice.error")
         })
          return;
        });
      this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'green',
           text: this.$t("tInvoice.uploadSuccess")
         })
    },
    addBankAccount() {
      db.collection("User")
        .doc(this.user.id)
        .update({
          bankAccountName: this.bankAccountName,
          IBAN: this.iban
        });
      this.writeToDatabase();
      this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'green',
           text: this.$t("tInvoice.addedBankAccount")
         })
    },
    send: async function() {
      if (this.chosenFile != null || this.checkboxCar) {
        if (this.validate()) {
          this.timestamp = Date.now();
          if (!this.checkboxCar) {
            this.uploadInvoiceReceipt();
          }
          if (
            this.user.bankAccountName == this.bankAccountName &&
            this.user.IBAN == this.iban
          ) {
            this.writeToDatabase();
            this.originalReceiptsDialog = true;
          } else {
            // show dialog to add bank account to profile
            this.dialog = true;
            this.originalReceiptsDialog = true;
            return;
          }
        } else {
          this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'red',
           text: this.$t("tInvoice.provideData")
         })
          return;
        }
      } else {
        this.$store.dispatch('setSnackbar', {
           showing: true,
           color: 'red',
           text: this.$t("tInvoice.uploadReceipt")
         })
        return;
      }
      //redirect to user profile

      this.resetForm();

    },
    addToProfileDialog (addToProfile) {
      if(addToProfile) {
        this.addBankAccount();
      } else {
        this.writeToDatabase();
      }
      setTimeout(() => this.$router.push('trip_invoice'), 1500);
    },
    getDate() {
      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear();
      if(dd<10) 
      {
          dd='0'+dd;
      } 

      if(mm<10) 
      {
          mm='0'+mm;
      } 
      return yyyy+'-'+mm+'-'+dd;
    }
  }
};
</script>


<style scope>
.tooltip_button {
  margin-right: 1em;
}
</style>

