import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import firebase from './firebaseConfig';
import router from './router'
import i18n from './i18n'
import { store } from './store/store'


Vue.config.productionTip = false

let app = '';

firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      vuetify,
      router,
      i18n,
      store,
      render: h => h(App)
    }).$mount('#app');
  }
});