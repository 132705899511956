<template>
  <v-app>
    <v-card width="800" class="mx-auto mt-5">
      <v-card-title>
        <h1 class="display-1" v-t="$t('changePswd.title')"></h1>
      </v-card-title>
      <v-card-text>
        <v-form ref="ChangePasswordForm" v-model="valid">
          <v-container>
            <v-row>
              <v-col>
                <v-text-field
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required]"
                  :type="show1 ? 'text' : 'password'"
                  name="input-10-2"
                  v-bind:label="$t('changePswd.oldPassword')"
                  class="input-group--focused"
                  id="OldPswd"
                  @click:append="show1 = !show1"
                  required
                  v-model="oldPswd"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.syn, rules.min]"
                  :type="show2 ? 'text' : 'password'"
                  name="input-10-2"
                  v-bind:label="$t('changePswd.newPassword')"
                  v-bind:hint="$t('changePswd.newPasswordhint')"
                  class="input-group--focused"
                  id="NewPswd"
                  @click:append="show2 = !show2"
                  required
                  v-model="newPswd"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-text-field
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.passwordMatch]"
                  :type="show3 ? 'text' : 'password'"
                  name="input-10-2"
                  v-bind:label="$t('changePswd.reNewPassword')"
                  v-bind:hint="$t('changePswd.reNewPasswordhint')"
                  class="input-group--focused"
                  id="ReNewPswd"
                  @click:append="show3 = !show3"
                  required
                  v-model="reNewPswd"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-col>
            <span v-t='"acInvoice.required"'></span>
        </v-col>
        <v-spacer />
        <v-btn :to="'userprofile'" color="grey">{{ $t("changePswd.cancel") }}</v-btn>
        <v-btn v-on:click="ChangePassword()" :disabled="!valid" color="success" v-t="$t('changePswd.submit')">
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-app>
</template>

<script>
import firebase from "../firebaseConfig.js";

export default {
  data() {
    return {
      show1: false,
      show2: false,
      show3: false,
      valid: false,
      oldPswd: "",
      newPswd: "",
      reNewPswd: "",
      password: "Password",
      rules: {
        required: value => !!value || this.$t('changePswd.pswdRequired'),
        syn: v => ( /\d/.test(v) && /[a-z]/.test(v) && /[A-Z]/.test(v) && /[!@#$%^&*)(+=._-]/.test(v) ) || this.$t("signup.passwordRulesInvalid"),
        min: v => (v && v.length >= 6) || this.$t('changePswd.pswdLength'),
        passwordMatch: () =>
          this.reNewPswd == this.newPswd || this.$t('changePswd.passwordNoMatch')
      }
    };
  },
  methods: {
    ChangePassword: function() {
      if (!this.valid) {
        return;
      }
      var user = firebase.auth().currentUser;
      var credential = firebase.auth.EmailAuthProvider.credential(
        user.email,
        this.oldPswd
      );
      var object = this;
      user
        .reauthenticateWithCredential(credential)
        .then(() => {
          if (this.newPswd == this.reNewPswd && this.newPswd.length >= 6) {
            user
              .updatePassword(this.newPswd)
              .then(() => {
                this.$store.dispatch('setSnackbar', {
                  showing: true,
                  color: 'green',
                  text: this.$t('changePswd.snackbar.changedPswd')
                })
                object.oldPswd = "";
                object.newPswd = "";
                object.reNewPswd = "";
                object.$refs.ChangePasswordForm.reset();
                setTimeout(() => this.$router.push('userprofile'), 1500);
              })
              .catch(function(error) {
                console.log(error);
                this.$store.dispatch('setSnackbar', {
                  showing: true,
                  color: 'red',
                  text: this.$t('changePswd.snackbar.internalError')
                })
              });
          } else {
            // kmmt gar nicht vor
            this.$store.dispatch('setSnackbar', {
                  showing: true,
                  color: 'red',
                  text: this.$t('changePswd.snackbar.notValid')
            })
          }
        })
        .catch(function() {
          // Old Password doesn't match.
          this.$store.dispatch('setSnackbar', {
                  showing: true,
                  color: 'red',
                  text: this.$t('changePswd.snackbar.wrongPswd')
            })
        });
      credential = "";
    }
  }
};
</script>

<style>
@media all and (max-width: 600px) {
  .dummy {
    display: none !important;
  }
};
h1{
   word-break: normal;
}
</style>
